import { mapState } from 'pinia'
import { usePromoCodeStore } from '@/stores/promocode'
import { useEventDataLayer } from '@/stores/event_data_layer'
import { useMarketStore } from '@/stores/market'
import { useCheckoutStore } from '@/stores/checkout'
import { convertCategoryTreeToArray, generateItemListName } from '@/utilities'
import { useDataLayerStore } from '@/stores/dataLayer'

export default {
  setup() {
    const marketStore = useMarketStore()
    const dataLayerStore = useDataLayerStore()
    return {
      dataLayerStore,
      marketStore,
    }
  },
  computed: {
    ...mapState(useMarketStore, [
      'profile_addresses',
      'selfCategory',
      'breadcrumbs',
    ]),
    ...mapState(usePromoCodeStore, [
      'personal_promo_code_subscription_data_layer',
      'has_subscription_promo_code_data_layer',
      'personal_promo_code_data_layer',
    ]),
    ...mapState(useCheckoutStore, ['orderPackage']),
    ...mapState(useEventDataLayer, [
      'begin_checkout_method',
      'begin_checkout_source',
      'data_layer_click_suggests',
    ]),
  },
  methods: {
    setDataLayerCategoryOpen(category) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'bt_catalog_category_open',
          category_id: category?.category_id,
          category_name: category?.category_name,
        })
      }
    },

    sendFilterApplied(filter, checkbox) {
      if (typeof dataLayer !== 'undefined') {
        let screen_name = ''
        if (this.$route.query.from_search) {
          screen_name = 'search'
        } else {
          if (this.$route.name.includes('brand')) screen_name = 'brand_page'
          if (this.$route.name.includes('merchant')) screen_name = 'merchant'
          if (this.$route.name.includes('categories')) screen_name = 'category'
          if (this.$route.name.includes('tags')) screen_name = 'tag_page'
        }
        const event_dataLayer = {
          event: 'filter_applied',
          filter_id: filter?.field_type === 'base' ? filter?.field : filter?.id,
          filter_name: filter.title,
          filter_value: checkbox?.marketing_name
            ? checkbox?.marketing_name
            : checkbox?.title,
          category_name: this.selfCategory?.data?.name,
          category_id: this.selfCategory?.data?.id,
          lang: this.$i18n?.locale,
          screen_name,
        }
        if (filter.max)
          event_dataLayer.filter_value = `${filter.min},${filter.max}`
        dataLayer.push(event_dataLayer)
      }
    },
    viewItemList(products) {
      const rout = this.$route.name
      if (typeof dataLayer !== 'undefined') {
        let type = ''
        let item_category = ''
        let listName = ''
        if (rout.includes('categories')) {
          type = 'category'
          listName = 'catalog'
        }
        if (rout.includes('search')) type = listName = 'search'
        if (rout.includes('brand')) type = listName = 'brand'
        if (rout.includes('mega-discounts'))
          type = item_category = listName = 'max_discount'
        if (rout.includes('merchant')) type = listName = 'seller'
        if (rout.includes('sale-day')) type = listName = 'flash_sale'
        if (rout.includes('tags')) {
          type = 'tags'
          listName = 'tag'
        }
        if (rout.includes('new-products')) type = listName = 'new_products'
        if (rout.includes('top-products')) type = listName = 'top_products'

        const categories = JSON.parse(
          JSON.stringify(this.breadcrumbs),
        ).reverse()
        const categoryItem = {}
        categories.map((category, index) => {
          categoryItem[
            index === 0 ? `item_category` : `item_category_${index + 1}`
          ] = `${category.name}_${category.id}`
        })

        const items = []
        const products_list = products || this.marketStore.products
        if (products_list.length) {
          products_list.slice(0, 10).map((product, index) => {
            const item = {
              item_name: product?.name,
              item_category: product?.category
                ? `${product?.category?.name || ''}_${
                    product?.category?.id || ''
                  }`
                : item_category,
              item_id: product.id,
              price: product.retail_price,
              item_brand: product?.brand || '',
              item_list_name: generateItemListName({
                route: this.$route,
                list_name: listName,
                query: this.$route?.query?.query,
                choice: this.$route?.query?.choice,
              }),
              index: index + 1,
              quantity: 1,
            }
            items.push(item)
          })
        }
        if (this.$route?.query?.from_search) {
          type = 'search'
        }
        const view_item_list = {
          event: 'view_item_list',
          type,
          ecommerce: { items: [...items] },
        }
        dataLayer.push({ ecommerce: null })
        dataLayer.push(view_item_list)
      }
    },
    async addToCartDataLayer(offer, offerUuid = null) {
      const lang = this.$i18n.locale || 'az'
      let categories = []
      const categoryItem = {}
      if (this.product?.category_id || offer?.category_id) {
        const categoryesTree = await this.marketStore.GetBreadcrumbsCategorys({
          categoryId: this.product?.category_id || offer?.category_id,
        })
        categories = convertCategoryTreeToArray(categoryesTree, lang)
        categories.map((category, index) => {
          categoryItem[
            index === 0 ? `item_category` : `item_category_${index + 1}`
          ] = `${category?.name}_${category.id}`
        })
      }

      let source = 'direct'
      if (this.marketStore.sourceSelectItem) {
        source = this.marketStore.sourceSelectItem
      }
      if (this.source) {
        source = this.source
      }
      const isProductPage = this.$route.name.includes('product-slug')
      if (this.$route?.query?.from_search && !isProductPage) {
        source = 'search'
      }
      const selectedOffer = offerUuid
        ? offer.offers?.find(item => item.uuid === offerUuid)
        : null
      const item_list_name = generateItemListName({
        route: this.$route,
        list_name: offer?.src_item_list_name,
        query: offer?.src_query,
        choice: offer?.src_choice,
      })
      const add_to_cart = {
        event: 'add_to_cart',
        currency: 'azn',
        source,
        ecommerce: {
          items: [
            {
              ...{
                item_name:
                  offer?.name ||
                  offer?.product_name ||
                  this.product?.name ||
                  this.product?.product_name,
                item_id: this.product_id || this.product?.id,
                price:
                  selectedOffer?.retail_price ||
                  offer?.retail_price ||
                  offer?.default_offer?.retail_price ||
                  this.product?.price,
                item_brand:
                  offer?.manufacturer ||
                  this.product?.brand ||
                  this.product?.brand?.name ||
                  '',
                ...((this.product?.categories || this.product?.category_id) && {
                  item_category: `${
                    this.product?.categories?.[0]?.name || ''
                  }_${this.product?.category_id}`,
                }),
                item_list_name,
                index: offer?.src_item_list_index + 1,
                item_list_id: offer?.item_add_src,
                quantity: this.quantityProduct
                  ? this.quantityProduct
                  : offer.min_qty || this.product_discount_quantity || 1,
              },
              ...categoryItem,
            },
          ],
        },
      }
      let self_category_id = null
      if (this.$route.name?.includes('categories')) {
        const categories = this.$route.params?.slug?.split('-')
        self_category_id = Array.isArray(categories) ? categories[0] : ''
      }
      // if (this.product?.categories?.length || categories?.length) {
      //   add_to_cart.ecommerce.item_list_id =
      //     this.product?.categories?.[0]?.id || categories?.[0]?.id
      //   add_to_cart.ecommerce.item_list_title =
      //     this.product?.categories?.[0]?.name || categories?.[0]?.name
      // }
      if (
        [
          'main',
          'items_list',
          'item_list',
          'item',
          'similar_items',
          'recommended',
          'recently_seen',
          'search',
          'bestsellers',
          'max_discount',
          'comparison',
          'orders',
          'reorder',
          'favourites',
          'availability_check',
          'arzum',
          'cart',
          'order_details',
          'favourites',
          'brand',
          'cross_sale',
          'new_products',
          'top_products',
          'kitfort',
          'flash_sale',
          'notification',
          'direct',
          'recommended',
          'seller',
          'reorder_part',
          'reorder_below_limit',
          'tag',
          'product_see_also',
          'top_products',
          'approved_limit',
          'personal_recommendations',
        ].includes(source)
      ) {
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push(function () {
            this.reset()
          })
          dataLayer.push(add_to_cart)
          this.marketStore.sourceSelectItem = ''
        }
      }
      this.dataLayerStore?.clearOptionAddToCart()
    },

    setViewSearchResults(params = '', pagination = 1) {
      // const click_suggests =
      //   this.$route?.name === 'search-term___ru' ||
      //   this.$route?.name === 'search-term___az'
      //     ? true
      //     : this.data_layer_click_suggests
      // const setEventDataLayer = useEventDataLayer()
      // if (
      //   typeof dataLayer !== 'undefined' &&
      //   this.$route?.query?.from_search &&
      //   click_suggests
      // ) {
      //   dataLayer.push({
      //     event: 'view_search_results',
      //     page_type: this.setPageType(),
      //     query: params,
      //     source: this.$route?.name.includes('category-slug')
      //       ? 'companies'
      //       : 'products',
      //     quantity: pagination,
      //     lang: this.$i18n?.locale,
      //   })
      //   setEventDataLayer.SET_DATA_CLICK_SUGGESTS(false)
      // }
    },
    // setPageType() {
    //   let page_type = ''
    //   switch (this.$route.name) {
    //     case 'merchant-merchant___ru':
    //       page_type = 'merchant'
    //       break
    //     case 'merchant-merchant___az':
    //       page_type = 'merchant'
    //       break
    //     case 'search-term___az':
    //       page_type = 'search'
    //       break
    //     case 'search-term___ru':
    //       page_type = 'search'
    //       break
    //     case 'tags-tag___az':
    //       page_type = 'tag'
    //       break
    //     case 'tags-tag___ru':
    //       page_type = 'tag'
    //       break
    //     case 'brands-brand___ru':
    //       page_type = 'brand'
    //       break
    //     case 'brands-brand___az':
    //       page_type = 'brand'
    //       break
    //
    //     case 'product-slug___ru':
    //       page_type = 'product'
    //       break
    //     case 'product-slug___az':
    //       page_type = 'product'
    //       break
    //     case 'categories-slug___ru':
    //       page_type = 'categories'
    //       break
    //     case 'categories-slug___az':
    //       page_type = 'categories'
    //       break
    //     default:
    //       ''
    //       break
    //   }
    //   return page_type
    // },
  },
}
