import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_08283616 from 'nuxt_plugin_plugin_08283616' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_4465b420 from 'nuxt_plugin_sentryserver_4465b420' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_7813b468 from 'nuxt_plugin_sentryclient_7813b468' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_47557304 from 'nuxt_plugin_plugin_47557304' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_d55bcc58 from 'nuxt_plugin_cookieuniversalnuxt_d55bcc58' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_portalvue_233115a7 from 'nuxt_plugin_portalvue_233115a7' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_pluginutils_1e7d398f from 'nuxt_plugin_pluginutils_1e7d398f' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2a159f1a from 'nuxt_plugin_pluginrouting_2a159f1a' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_21e99ad6 from 'nuxt_plugin_pluginmain_21e99ad6' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_metaplugin_737b6b5b from 'nuxt_plugin_metaplugin_737b6b5b' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_782310cf from 'nuxt_plugin_iconplugin_782310cf' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_2e0a522c from 'nuxt_plugin_axios_2e0a522c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_001a0492 from 'nuxt_plugin_deviceplugin_001a0492' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_distplugin1e93ed27_b335d7d0 from 'nuxt_plugin_distplugin1e93ed27_b335d7d0' // Source: ./dist.plugin.1e93ed27.mjs (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_firebase_4f256b5c from 'nuxt_plugin_firebase_4f256b5c' // Source: ../plugins/firebase.client (mode: 'client')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_vuelidate_4345260a from 'nuxt_plugin_vuelidate_4345260a' // Source: ../plugins/vuelidate (mode: 'all')
import nuxt_plugin_lazyload_53bd2c70 from 'nuxt_plugin_lazyload_53bd2c70' // Source: ../plugins/lazy-load.client (mode: 'client')
import nuxt_plugin_infinitescroll_47c5edee from 'nuxt_plugin_infinitescroll_47c5edee' // Source: ../plugins/infinite-scroll.client (mode: 'client')
import nuxt_plugin_bloomreach_2155bd1a from 'nuxt_plugin_bloomreach_2155bd1a' // Source: ../plugins/bloomreach.client (mode: 'client')
import nuxt_plugin_browserstart_54e0a1d2 from 'nuxt_plugin_browserstart_54e0a1d2' // Source: ../plugins/browser-start.client (mode: 'client')
import nuxt_plugin_appredirect_0f583f3b from 'nuxt_plugin_appredirect_0f583f3b' // Source: ../plugins/app-redirect.client (mode: 'client')
import nuxt_plugin_vue2datepicker_c4f3d9dc from 'nuxt_plugin_vue2datepicker_c4f3d9dc' // Source: ../plugins/vue2-datepicker.client (mode: 'client')
import nuxt_plugin_route_338f5eda from 'nuxt_plugin_route_338f5eda' // Source: ../plugins/route (mode: 'all')
import nuxt_plugin_rtb_5e46632a from 'nuxt_plugin_rtb_5e46632a' // Source: ../plugins/rtb (mode: 'all')
import nuxt_plugin_registerServiceWorker_1cd95582 from 'nuxt_plugin_registerServiceWorker_1cd95582' // Source: ../plugins/registerServiceWorker.client (mode: 'client')
import nuxt_plugin_donutchart_e5ad46cc from 'nuxt_plugin_donutchart_e5ad46cc' // Source: ../plugins/donut-chart.js (mode: 'client')
import nuxt_plugin_vueyoutubeembed_1b7c021c from 'nuxt_plugin_vueyoutubeembed_1b7c021c' // Source: ../plugins/vue-youtube-embed (mode: 'client')
import nuxt_plugin_elementui_a6a1b20a from 'nuxt_plugin_elementui_a6a1b20a' // Source: ../plugins/element-ui (mode: 'all')
import nuxt_plugin_plugin_dd43e330 from 'nuxt_plugin_plugin_dd43e330' // Source: ./auth/plugin.js (mode: 'all')
import nuxt_plugin_meta_598eade0 from 'nuxt_plugin_meta_598eade0' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$umico', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$umico : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$umico
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#242B58"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"hid":"description","name":"description","content":"UMICO.AZ"}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Ffonts.googleapis.com"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com"},{"rel":"preconnect","href":"https:\u002F\u002Fesputnik.com\u002F"},{"rel":"preconnect","href":"https:\u002F\u002Flivechatv2.chat2desk.com\u002F"},{"rel":"preconnect","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002F"},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss2?family=Roboto:wght@300;400;500;700&display=swap"},{"rel":"stylesheet","href":"https:\u002F\u002Fcdnjs.cloudflare.com\u002Fajax\u002Flibs\u002Fleaflet\u002F1.9.4\u002Fleaflet.min.css"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"src":"\u002Fjs\u002Fgoogle_tag_manager.js","defer":true,"body":false}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__umico_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__UMICO__ && window.__UMICO__.state) {
      store.replaceState(window.__UMICO__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_08283616 === 'function') {
    await nuxt_plugin_plugin_08283616(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4465b420 === 'function') {
    await nuxt_plugin_sentryserver_4465b420(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_7813b468 === 'function') {
    await nuxt_plugin_sentryclient_7813b468(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_47557304 === 'function') {
    await nuxt_plugin_plugin_47557304(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_d55bcc58 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_d55bcc58(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_233115a7 === 'function') {
    await nuxt_plugin_portalvue_233115a7(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1e7d398f === 'function') {
    await nuxt_plugin_pluginutils_1e7d398f(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2a159f1a === 'function') {
    await nuxt_plugin_pluginrouting_2a159f1a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_21e99ad6 === 'function') {
    await nuxt_plugin_pluginmain_21e99ad6(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_737b6b5b === 'function') {
    await nuxt_plugin_metaplugin_737b6b5b(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_782310cf === 'function') {
    await nuxt_plugin_iconplugin_782310cf(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2e0a522c === 'function') {
    await nuxt_plugin_axios_2e0a522c(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_001a0492 === 'function') {
    await nuxt_plugin_deviceplugin_001a0492(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin1e93ed27_b335d7d0 === 'function') {
    await nuxt_plugin_distplugin1e93ed27_b335d7d0(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_firebase_4f256b5c === 'function') {
    await nuxt_plugin_firebase_4f256b5c(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4345260a === 'function') {
    await nuxt_plugin_vuelidate_4345260a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lazyload_53bd2c70 === 'function') {
    await nuxt_plugin_lazyload_53bd2c70(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infinitescroll_47c5edee === 'function') {
    await nuxt_plugin_infinitescroll_47c5edee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bloomreach_2155bd1a === 'function') {
    await nuxt_plugin_bloomreach_2155bd1a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_browserstart_54e0a1d2 === 'function') {
    await nuxt_plugin_browserstart_54e0a1d2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_appredirect_0f583f3b === 'function') {
    await nuxt_plugin_appredirect_0f583f3b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2datepicker_c4f3d9dc === 'function') {
    await nuxt_plugin_vue2datepicker_c4f3d9dc(app.context, inject)
  }

  if (typeof nuxt_plugin_route_338f5eda === 'function') {
    await nuxt_plugin_route_338f5eda(app.context, inject)
  }

  if (typeof nuxt_plugin_rtb_5e46632a === 'function') {
    await nuxt_plugin_rtb_5e46632a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_registerServiceWorker_1cd95582 === 'function') {
    await nuxt_plugin_registerServiceWorker_1cd95582(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_donutchart_e5ad46cc === 'function') {
    await nuxt_plugin_donutchart_e5ad46cc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueyoutubeembed_1b7c021c === 'function') {
    await nuxt_plugin_vueyoutubeembed_1b7c021c(app.context, inject)
  }

  if (typeof nuxt_plugin_elementui_a6a1b20a === 'function') {
    await nuxt_plugin_elementui_a6a1b20a(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_dd43e330 === 'function') {
    await nuxt_plugin_plugin_dd43e330(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_598eade0 === 'function') {
    await nuxt_plugin_meta_598eade0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
