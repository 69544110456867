export const RESOLUTIONS = {
  sm: 736,
  md: 992,
  lg: 1280,
  xl: 1312,
  xxl: 100000,
}

// TODO refactor whitelist after BIRBANK fix
export const WHITELISTED_PATHS_IOS = [
  '/',
  '/ru',
  '/az',
  '/brands/*',
  '/*/brands/*',
  '/product/*',
  '/*/product/*',
  '/categories',
  '/*/categories',
  '/categories/*',
  '/*/categories/*',
  '/search/*',
  '/*/search/*',
  '/mega-discounts/*',
  '/mega-discounts',
  '/*/mega-discounts',
  '/*/mega-discounts/*',
  '/merchant/*',
  '/*/merchant/*',
  '/account/orders',
  '/account/orders/*',
  '/*/account/orders/*',
  '/*/account/orders',
  '/sale-day/*',
  '/sale-day',
  '*/sale-day',
  '/*/sale-day/*',
  '/new-products/*',
  '/new-products',
  '/*/new-products',
  '/*/new-products/*',
  '/smart-categories/*',
  '/smart-categories',
  '/*/smart-categories',
  '/*/smart-categories/*',
  '/tags/*',
  '/tags',
  '/*/tags/*',
  '/promotion/*',
  '/promotion',
  '/*/promotion',
  '/*/promotion/*',
  '/services/*',
  '/*/services/*',
  '*/credit_line',
  '*/credit_line/*',
  '/credit_line/*',
  '/promo_page/*',
  '/*/promo_page/*',
  '/coupon/*',
  '/*/coupon/*',
  '/product-assessment/*',
  '/*/product-assessment/*',
  '/*/product-assessment',
  '/product-assessment',
  '/smart_bonus/*',
  '/smart_bonus',
  '/*/smart_bonus',
  '/sp',
  '/*/sp',
  '/*/sp/*',
  '/bonuses_main',
  '/*/bonuses_main/*',
  '/returns/*',
  '/returns',
  '/*/returns',
  '/*/returns/*',
]
export const WHITELISTED_PATHS_ANDROID = [
  // '/',
  // '/ru',
  // '/az',
  '/brands/*',
  '/*/brands/*',
  '/product/*',
  '/*/product/*',
  // '/categories',
  // '/*/categories',
  // '/categories/*',
  // '/*/categories/*',
  '/search/*',
  '/*/search/*',
  '/mega-discounts/*',
  '/mega-discounts',
  '/*/mega-discounts',
  '/*/mega-discounts/*',
  '/merchant/*',
  '/*/merchant/*',
  '/account/orders',
  '/account/orders/*',
  '/*/account/orders/*',
  '/*/account/orders',
  '/sale-day/*',
  '/sale-day',
  '*/sale-day',
  '/*/sale-day/*',
  '/new-products/*',
  '/new-products',
  '/*/new-products',
  '/*/new-products/*',
  '/smart-categories/*',
  '/smart-categories',
  '/*/smart-categories',
  '/*/smart-categories/*',
  '/tags/*',
  '/tags',
  '/*/tags/*',
  '/promotion/*',
  '/promotion',
  '/*/promotion',
  '/*/promotion/*',
  '/services/*',
  '/*/services/*',
  '*/credit_line',
  '*/credit_line/*',
  '/credit_line/*',
  '/promo_page/*',
  '/*/promo_page/*',
  '/coupon/*',
  '/*/coupon/*',
  '/product-assessment/*',
  '/*/product-assessment/*',
  '/*/product-assessment',
  '/product-assessment',
  '/smart_bonus/*',
  '/smart_bonus',
  '/*/smart_bonus',
  '/sp',
  '/*/sp',
  '/*/sp/*',
  '/bonuses_main',
  '/*/bonuses_main/*',
  '/returns/*',
  '/returns',
  '/*/returns',
  '/*/returns/*',
]
export const CATEGORY_ICON = {
  0: 'mega-discounts',
  1: 'food_drink',
  2: 'mobile',
  9: 'clothes',
  15: 'notebook',
  27: 'hobby_and_books',
  38: 'parfume',
  60: 'child',
  70: 'tv',
  82: 'home',
  88: 'electronics',
  106: 'appliances',
  112: 'recreation_entertainment',
  164: 'education',
  175: 'travel',
  180: 'auto_moto',
  204: 'photo',
  226: 'supermarkety',
  228: 'cables',
  235: 'auto_electronics',
  249: 'smart_house',
  257: 'parfume',
  288: 'cable',
  937: 'home_garden',
  779: 'mom_son',
  746: 'household_chemicals',
  516: 'sport',
  1340: 'hobby_and_books',
  1657: 'office',
  1708: 'auto_products',
  2164: 'pets',
  2266: 'accessory',
  2492: 'groceries',
  3569: 'fan',
  3003: 'clothes',
  3890: 'outlet',
}
export const CATALOG_CATEGORY_ICON = {
  0: 'mega-discounts',
  1: 'electronic_bit',
  2: 'mobile',
  15: 'notebook',
  70: 'tv',
  88: 'electronics',
  106: 'appliances',
  204: 'photo',
  228: 'cables',
  235: 'auto_electronics',
  249: 'smart_house',
  257: 'parfume',
  288: 'cable',
  937: 'home_garden',
  779: 'mom_son',
  746: 'household_chemicals',
  516: 'sport',
  1340: 'hobby_and_books',
  1657: 'office',
  1708: 'auto_products',
  2164: 'pets',
  2266: 'accessory',
  2492: 'groceries',
  3569: 'fan',
  3003: 'clothes',
  3890: 'outlet',
  4497: 'duncan',
  4641: 'hand-card',
  4835: 'gaming',
}
export const CATALOG_CATEGORY_IMAGE = {
  1: 'https://storage-dev.umico.az/cpcustomerdev01/ce35e9178a41194116c083b72a9c3281.png',
  270: 'https://storage-dev.umico.az/cpcustomerdev01/513df7460b0909eff994d5e183acd026.png',
  1094: 'https://storage-dev.umico.az/cpcustomerdev01/cb56118e5fe3a0d5fb6df4082fa7a4e5.png',
  1523: 'https://storage-dev.umico.az/cpcustomerdev01/1389bd3d9fbf321b06079a89883e625d.png',
  1873: 'https://storage-dev.umico.az/cpcustomerdev01/9f7291b4aa1be8c9e219747be83edd82.png',
  2436: 'https://storage-dev.umico.az/cpcustomerdev01/7e9ddccd050c1827590e1248864a36fc.png',
  4044: 'https://storage-dev.umico.az/cpcustomerdev01/1893511fe1048343245b8826de58dd8b.png',
  4911: 'https://storage-dev.umico.az/cpcustomerdev01/1d8bad68e43ab18bc3b2f96b550f1b21.png',
  5678: 'https://storage-dev.umico.az/cpcustomerdev01/d79132da53bc05bfd1d844d9df070fe5.png',
  5695: 'https://storage-dev.umico.az/cpcustomerdev01/27a57a652a9420008d39695a005a153f.png',
}
export const PRODUCT_AVAILABILITIES = {
  available: { color: '#53cebf' },
  no_control: { color: 'red' },
  n_a: { color: '#9497AD' },
  expected: { color: 'red' },
  service: { color: 'red' },
  preorder: { color: 'red' },
}

export const PRODUCT_FILTER_TYPES = {
  supply_type: 'supply_type_in',
  origin_country: 'origin_country_in',
  manufacturer: 'manufacturer_in',
  seller_id: 'seller_id_in',
  merchant_uuid: 'merchant_uuid_in',
  retail_price_lteq: 'retail_price_lteq',
  retail_price_gteq: 'retail_price_gteq',
  has_taxit_available: 'has_taxit_available',
  has_installment: 'has_installment',
  premium_partner: 'premium_partner',
  has_discount: 'has_discount',
  facets: 'facets',
  status: 'status',
  with_discount: 'with_discount',
  in_stock: 'in_stock',
  price_from: 'price_from',
  price_to: 'price_to',
  price_min: 'price_min',
  price_max: 'price_max',
  brands: 'brands',
  marketing_name_id: 'marketing_name_id',
  facets_multiple: 'facets_multiple',
  facets_range: 'facets_range',
  category_id: 'category_id',
}

export const STR_IMAGE_URL = {
  Production: 'https://strgimgr.umico.az',
  Staging: 'https://strgimgrsstage.umico.az',
  Testing: 'https://strgimgrstest.umico.az',
  Development: 'https://strgimgrsdev.umico.az',
}

export const APPINSIGHTS_INSTRUMENTATION_KEY = {
  Production: 'b94481dd-a68b-456a-9ee3-05f24ca73bf5',
  Staging: '4aabcbee-682a-4ff4-a6f8-966e7fcc9ddd',
  Testing: 'e46e4ff3-0530-48ff-ba59-9c2ae2360e5d',
  Development: 'ec02c0c1-68f5-4b9f-ab04-c4754bc7d89e',
}

export const INCLUDE_FIELDS =
  'id,old_price,retail_price,availability,preorder_available,default_offer_id,img_url_thumbnail,name,manufacturer,avail_check,status,slugged_name,discount,default_marketing_name,ratings,offers,offers.retail_price,offers.id,offers.marketing_name,offers.merchant_uuid,category_id,default_offer_allow_qty,offers.uuid,offers.partner_rating,default_merchant_rating,qty,default_stock_control_mode,default_show_stock_qty_threshold,best_installment_offer_id,offers.supplier_id,is_bulk_heavy,default_merchant_uuid,offers.seller_marketing_name'

export const INCLUDE_FIELDS_PRODUCT =
  'seo_title,seo_description,offers,offers.partner_rating,offers.retail_price,offers.marketing_name,offers.seller_id,offers.seller_marketing_name,default_offer_id,offers.id,offers.old_price,offers.uuid,offers.merchant_uuid,is_assortment,offers.avail_check,offers.comment,offers.comment_az,offers.comment_ru,product_labels,slugged_name_ru,slugged_name_az,id,old_price,retail_price,img_url_thumbnail,description,description_az,description_ru,default_offer_uuid,img_urls_secondary,img_url_original,img_url_standard,img_url_zoom,default_comment,variant_code,name,categories,default_discount_effective_end_date,default_supplier_id,default_merchant_uuid,slugged_name,price,uuid,updated_at,ratings,search_tags,default_marketing_name,default_discount_effective_start_date,default_offer_allow_qty,retail_price,availability_checked_at,activated_at,avail_check,status,similar_products,complementary_products,category_id,manufacturer,custom_fields,product_id,videos,main_video,min_qty,default_merchant_vat_payer,loyalty_cashback,is_bulk_heavy,default_merchant_rating,show_tech_description,description_outlet,qty,default_show_stock_qty_threshold,non_refundable,default_offer_with_additional_service'

export const UMICO_MARKET_PARTNER_ID = {
  Production: '239449d1-713c-4a42-8ce3-7821afcab364',
  Staging: 'b2a05c1d-a86c-45ac-880f-6d09d1639e70',
  Testing: 'de488d75-3f86-4e55-bf0c-e8e22f9b1072',
  Development: '73fc97e2-9e0e-4559-93fe-53021637fef6',
}

export const PER_PAGE = 24

export const ALL_COUNTRIES = [
  ['Afghanistan (‫افغانستان‬‎)', 'af', '93'],
  ['Albania (Shqipëri)', 'al', '355'],
  ['Algeria (‫الجزائر‬‎)', 'dz', '213'],
  ['American Samoa', 'as', '1684'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Anguilla', 'ai', '1264'],
  ['Antigua and Barbuda', 'ag', '1268'],
  ['Argentina', 'ar', '54'],
  ['Armenia (Հայաստան)', 'am', '374'],
  ['Aruba', 'aw', '297'],
  ['Australia', 'au', '61', 0],
  ['Austria (Österreich)', 'at', '43'],
  ['Azerbaijan (Azərbaycan)', 'az', '994'],
  ['Bahamas', 'bs', '1242'],
  ['Bahrain (‫البحرين‬‎)', 'bh', '973'],
  ['Bangladesh (বাংলাদেশ)', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Belarus (Беларусь)', 'by', '375'],
  ['Belgium (België)', 'be', '32'],
  ['Belize', 'bz', '501'],
  ['Benin (Bénin)', 'bj', '229'],
  ['Bermuda', 'bm', '1441'],
  ['Bhutan (འབྲུག)', 'bt', '975'],
  ['Bolivia', 'bo', '591'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brazil (Brasil)', 'br', '55'],
  ['British Indian Ocean Territory', 'io', '246'],
  ['British Virgin Islands', 'vg', '1284'],
  ['Brunei', 'bn', '673'],
  ['Bulgaria (България)', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi (Uburundi)', 'bi', '257'],
  ['Cambodia (កម្ពុជា)', 'kh', '855'],
  ['Cameroon (Cameroun)', 'cm', '237'],
  [
    'Canada',
    'ca',
    '1',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cape Verde (Kabu Verdi)', 'cv', '238'],
  ['Caribbean Netherlands', 'bq', '599', 1],
  ['Cayman Islands', 'ky', '1345'],
  ['Central African Republic (République centrafricaine)', 'cf', '236'],
  ['Chad (Tchad)', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China (中国)', 'cn', '86'],
  ['Christmas Island', 'cx', '61', 2],
  ['Cocos (Keeling) Islands', 'cc', '61', 1],
  ['Colombia', 'co', '57'],
  ['Comoros (‫جزر القمر‬‎)', 'km', '269'],
  ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243'],
  ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242'],
  ['Cook Islands', 'ck', '682'],
  ['Costa Rica', 'cr', '506'],
  ['Côte d’Ivoire', 'ci', '225'],
  ['Croatia (Hrvatska)', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', 0],
  ['Cyprus (Κύπρος)', 'cy', '357'],
  ['Czech Republic (Česká republika)', 'cz', '420'],
  ['Denmark (Danmark)', 'dk', '45'],
  ['Djibouti', 'dj', '253'],
  ['Dominica', 'dm', '1767'],
  [
    'Dominican Republic (República Dominicana)',
    'do',
    '1',
    2,
    ['809', '829', '849'],
  ],
  ['Ecuador', 'ec', '593'],
  ['Egypt (‫مصر‬‎)', 'eg', '20'],
  ['El Salvador', 'sv', '503'],
  ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
  ['Eritrea', 'er', '291'],
  ['Estonia (Eesti)', 'ee', '372'],
  ['Ethiopia', 'et', '251'],
  ['Falkland Islands (Islas Malvinas)', 'fk', '500'],
  ['Faroe Islands (Føroyar)', 'fo', '298'],
  ['Fiji', 'fj', '679'],
  ['Finland (Suomi)', 'fi', '358', 0],
  ['France', 'fr', '33'],
  ['French Guiana (Guyane française)', 'gf', '594'],
  ['French Polynesia (Polynésie française)', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgia (საქართველო)', 'ge', '995'],
  ['Germany (Deutschland)', 'de', '49'],
  ['Ghana (Gaana)', 'gh', '233'],
  ['Gibraltar', 'gi', '350'],
  ['Greece (Ελλάδα)', 'gr', '30'],
  ['Greenland (Kalaallit Nunaat)', 'gl', '299'],
  ['Grenada', 'gd', '1473'],
  ['Guadeloupe', 'gp', '590', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502'],
  ['Guernsey', 'gg', '44', 1],
  ['Guinea (Guinée)', 'gn', '224'],
  ['Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haiti', 'ht', '509'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong (香港)', 'hk', '852'],
  ['Hungary (Magyarország)', 'hu', '36'],
  ['Iceland (Ísland)', 'is', '354'],
  ['India (भारत)', 'in', '91'],
  ['Indonesia', 'id', '62'],
  ['Iran (‫ایران‬‎)', 'ir', '98'],
  ['Iraq (‫العراق‬‎)', 'iq', '964'],
  ['Ireland', 'ie', '353'],
  ['Isle of Man', 'im', '44', 2],
  ['Israel (‫ישראל‬‎)', 'il', '972'],
  ['Italy (Italia)', 'it', '39', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japan (日本)', 'jp', '81'],
  ['Jersey', 'je', '44', 3],
  ['Jordan (‫الأردن‬‎)', 'jo', '962'],
  ['Kazakhstan (Казахстан)', 'kz', '7', 1],
  ['Kenya', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait (‫الكويت‬‎)', 'kw', '965'],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
  ['Laos (ລາວ)', 'la', '856'],
  ['Latvia (Latvija)', 'lv', '371'],
  ['Lebanon (‫لبنان‬‎)', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Liberia', 'lr', '231'],
  ['Libya (‫ليبيا‬‎)', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lithuania (Lietuva)', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macau (澳門)', 'mo', '853'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
  ['Madagascar (Madagasikara)', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malaysia', 'my', '60'],
  ['Maldives', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marshall Islands', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritania (‫موريتانيا‬‎)', 'mr', '222'],
  ['Mauritius (Moris)', 'mu', '230'],
  ['Mayotte', 'yt', '262', 1],
  ['Mexico (México)', 'mx', '52'],
  ['Micronesia', 'fm', '691'],
  ['Moldova (Republica Moldova)', 'md', '373'],
  ['Monaco', 'mc', '377'],
  ['Mongolia (Монгол)', 'mn', '976'],
  ['Montenegro (Crna Gora)', 'me', '382'],
  ['Montserrat', 'ms', '1664'],
  ['Morocco (‫المغرب‬‎)', 'ma', '212', 0],
  ['Mozambique (Moçambique)', 'mz', '258'],
  ['Myanmar (Burma) (မြန်မာ)', 'mm', '95'],
  ['Namibia (Namibië)', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal (नेपाल)', 'np', '977'],
  ['Netherlands (Nederland)', 'nl', '31'],
  ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
  ['New Zealand', 'nz', '64'],
  ['Nicaragua', 'ni', '505'],
  ['Niger (Nijar)', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Niue', 'nu', '683'],
  ['Norfolk Island', 'nf', '672'],
  ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850'],
  ['Northern Mariana Islands', 'mp', '1670'],
  ['Norway (Norge)', 'no', '47', 0],
  ['Oman (‫عُمان‬‎)', 'om', '968'],
  ['Pakistan (‫پاکستان‬‎)', 'pk', '92'],
  ['Palau', 'pw', '680'],
  ['Palestine (‫فلسطين‬‎)', 'ps', '970'],
  ['Panama (Panamá)', 'pa', '507'],
  ['Papua New Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Peru (Perú)', 'pe', '51'],
  ['Philippines', 'ph', '63'],
  ['Poland (Polska)', 'pl', '48'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
  ['Qatar (‫قطر‬‎)', 'qa', '974'],
  ['Réunion (La Réunion)', 're', '262', 0],
  ['Romania (România)', 'ro', '40'],
  ['Russia (Россия)', 'ru', '7', 0],
  ['Rwanda', 'rw', '250'],
  ['Saint Barthélemy', 'bl', '590', 1],
  ['Saint Helena', 'sh', '290'],
  ['Saint Kitts and Nevis', 'kn', '1869'],
  ['Saint Lucia', 'lc', '1758'],
  ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
  ['Saint Vincent and the Grenadines', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
  ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966'],
  ['Senegal (Sénégal)', 'sn', '221'],
  ['Serbia (Србија)', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapore', 'sg', '65'],
  ['Sint Maarten', 'sx', '1721'],
  ['Slovakia (Slovensko)', 'sk', '421'],
  ['Slovenia (Slovenija)', 'si', '386'],
  ['Solomon Islands', 'sb', '677'],
  ['Somalia (Soomaaliya)', 'so', '252'],
  ['South Africa', 'za', '27'],
  ['South Korea (대한민국)', 'kr', '82'],
  ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211'],
  ['Spain (España)', 'es', '34'],
  ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
  ['Sudan (‫السودان‬‎)', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Svalbard and Jan Mayen', 'sj', '47', 1],
  ['Swaziland', 'sz', '268'],
  ['Sweden (Sverige)', 'se', '46'],
  ['Switzerland (Schweiz)', 'ch', '41'],
  ['Syria (‫سوريا‬‎)', 'sy', '963'],
  ['Taiwan (台灣)', 'tw', '886'],
  ['Tajikistan', 'tj', '992'],
  ['Tanzania', 'tz', '255'],
  ['Thailand (ไทย)', 'th', '66'],
  ['Timor-Leste', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tokelau', 'tk', '690'],
  ['Tonga', 'to', '676'],
  ['Trinidad and Tobago', 'tt', '1868'],
  ['Tunisia (‫تونس‬‎)', 'tn', '216'],
  ['Turkey (Türkiye)', 'tr', '90'],
  ['Turkmenistan', 'tm', '993'],
  ['Turks and Caicos Islands', 'tc', '1649'],
  ['Tuvalu', 'tv', '688'],
  ['U.S. Virgin Islands', 'vi', '1340'],
  ['Uganda', 'ug', '256'],
  ['Ukraine (Україна)', 'ua', '380'],
  ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971'],
  ['United Kingdom', 'gb', '44', 0],
  ['United States', 'us', '1', 0],
  ['Uruguay', 'uy', '598'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998'],
  ['Vanuatu', 'vu', '678'],
  ['Vatican City (Città del Vaticano)', 'va', '39', 1],
  ['Venezuela', 've', '58'],
  ['Vietnam (Việt Nam)', 'vn', '84'],
  ['Wallis and Futuna (Wallis-et-Futuna)', 'wf', '681'],
  ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', 1],
  ['Yemen (‫اليمن‬‎)', 'ye', '967'],
  ['Zambia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
  ['Åland Islands', 'ax', '358', 1],
]

export const WHITE_LIST_MARKET_PRODUCTS_PARAMS = [
  'q[id_in]',
  'q[uuid_in]',
  'q[external_id_in]',
  'q[query_test_eq]',
  'q[full_text]',
  'q[express_operational_hours]',
  'q[category_id_in]',
  'q[variant_code_eq]',
  'q[search_variant_code_eq]',
  'q[main_category_id_eq]',
  'q[marketing_name_id_eq]',
  'q[seller_marketing_name_id_eq]',
  'q[seller_id_in]',
  'q[merchant_uuid_in]',
  'q[label_id_eq]',
  'q[promo_collection_code_eq]',
  'q[seo_tag_eq]',
  'q[search_tag_eq]',
  'q[origin_country_in]',
  'q[manufacturer_in]',
  'q[status_in]',
  'q[retail_price_lteq]',
  'q[retail_price_gteq]',
  'q[discounted_today_eq]',
  'q[has_discount]',
  'q[has_badge_hot]',
  'q[default_fake_discount_eq]',
  'q[has_installment]',
  'q[default_facets]',
  'q[facets]',
  'q[s]',
  'q[market_order]',
  'q[search_mode]',
  'q[response_mode]',
  'q[opaque_id]',
  'q[synonyms_active]',
  'q[only_variant_custom_fields]',
  'search_fields',
  'include_fields',
  'exclude_fields',
  'page',
  'per_page',
  'include_filters',
  'filters_page',
  'filters_per_page',
  'show_es_query',
  'current_operational_hour',
  'q[ret_price_min]',
  'q[ret_price_max]',
  'category_id',
  'q[status]',
  'category',
  'q[premium_partner]',
  // new api params
  'sort',
  'premium_partner',
  'with_discount',
  'seller_id',
  'in_stock',
  'price_from',
  'price_to',
  'brands',
  'marketing_name_id',
  'facets_range',
  'facets_multiple',
  'category_id',
  // TODO не нужные параметры при отправки на бек возможные последствия отмены баги с ивентами (фиксить не возвратом из в )
  // 'from_search',
  // 'event',
  // 'query',
  // 'choice',
  // 'tips_type',
  // 'synonyms',
]

export const SEARCH_QUERIES = [
  'from_search',
  'event',
  'query',
  'choice',
  'tips_type',
  'search_type',
]

export const DEBUG_KEY = 'LSB1OAkcribJ'
