import { render, staticRenderFns } from "./FooterBlock.vue?vue&type=template&id=a1962f42"
import script from "./FooterBlock.vue?vue&type=script&lang=js"
export * from "./FooterBlock.vue?vue&type=script&lang=js"
import style0 from "./FooterBlock.vue?vue&type=style&index=0&id=a1962f42&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterTopFooter: require('/usr/src/app/components/Footer/TopFooter.vue').default})
