export default {
  isPremiumPartner(state) {
    return product => {
      const current_product = product || state.product
      return current_product?.default_offer?.seller?.role_name === 'FBU'
    }
  },
  isProductCB(state) {
    return () => {
      const listCartSellers = state.cart?.sellers.map(seller => seller) || []

      const listCartProductsRoleCB =
        listCartSellers
          ?.filter(seller => seller?.role === 'CB')
          ?.flatMap(seller => seller.line_items) || []

      const isActive = listCartProductsRoleCB.find(
        product => product.state === 'active',
      )
      if (isActive) {
        return listCartProductsRoleCB.find(
          product => product.is_selected_for_checkout === true,
        )
      } else {
        return false
      }
    }
  },
  filteredPudosAll(state) {
    return (keyword = null) => {
      return keyword
        ? state.pudos_all.filter(pudo =>
            `${pudo.name}, ${pudo.address.city_name}, ${pudo.address.street}${
              pudo.address.building ? ',' : ''
            }${pudo.address.building}`
              .trim()
              .toLowerCase()
              .includes(keyword.trim().toLowerCase()),
          )
        : state.pudos_all
    }
  },
  productFeatures(state) {
    return (state.product?.custom_fields || []).map(field => {
      const customField = state.productCategory?.custom_fields.find(item => {
        return item?.master_field_id === field?.master_field_id
      })
      return {
        id: customField?.master_field_id,
        name: customField?.name,
        values: field?.values,
        // will be updated with tags endpoint
        href: null,
      }
    })
  },
  productOtherOffers(state) {
    const otherOffers = []
    state.productOffers.forEach(offer => {
      if (state.product?.default_offer?.uuid !== offer?.uuid) {
        otherOffers.push(offer)
      }
    })
    return otherOffers
  },
  childCategoryItems(state) {
    const categoryFilter = state.filters?.default?.find(filter => {
      return filter.field === 'category_id'
    })
    if (!categoryFilter) return []
    return categoryFilter.buckets
      .map(bucket => bucket?.children)
      .reduce((acc, currentValue) => {
        return acc.concat(currentValue)
      }, [])
  },
  hasDeliveryPossible(state) {
    return () => {
      let hasDeliveryPossible = false
      state.cart?.sellers?.forEach(seller => {
        hasDeliveryPossible = seller.line_items.find(
          line_item => line_item?.delivery_possible === false,
        )
      })
      return !!hasDeliveryPossible
    }
  },
}
