export default {
  selectedCoordinate(state) {
    return state.userCoordinate || state.geo.selectedCoordinate
  },
  loggedIn() {
    return !!this.$nuxt.store.state.auth.access_token
  },
  cookieCity() {
    // properties not reactive
    return {
      id: this.$nuxt.$cookies.get('cityId') || 1,
      selected: this.$nuxt.$cookies.get('citySelected') || false,
    }
  },
  cookieCitySelect() {
    return this.$nuxt.$cookies.get('cityId') || 1
  },
  cookieCitySelected() {
    return this.$nuxt.$cookies.get('citySelected') || false
  },
}
