import { useCustomer } from '@/stores/customer'
import { ref, onBeforeMount, computed, watch } from 'vue'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'
import { useContext } from '@nuxtjs/composition-api'


export default {
  __name: 'CityPickerButton',
  setup(__props) {

const { app } = useContext()
const customerStore = useCustomer()
const uiStore = useUiStore()
const { cities, isLoggedIn, cookieCity, customerSelectedCity } =
  storeToRefs(customerStore)

const selectedCityLoggedIn = computed(() => {
  return customerSelectedCity.value?.attributes?.name
})

const selectedCityAnonymous = ref('')

const selectedCity = computed(() => {
  return isLoggedIn.value
    ? selectedCityLoggedIn.value
    : selectedCityAnonymous.value
})
const openCityPickModal = () => {
  uiStore.isCityPickerPopupVisible = true
}
const isCitySelected = () => {
  return !!app.$cookies.get('citySelected')
}
onBeforeMount(async () => {
  if (!cities.value.length) {
    await customerStore.getCitiesByCountry(1)
  }
  if (!isLoggedIn.value) {
    let city
    if (cookieCity?.value.id && cookieCity?.value.selected) {
      city = cities.value.find(city => +city.id === +cookieCity.value.id)
    } else {
      customerStore.setCookieCityId(1)
      city = cities.value.find(city => +city.id === 1)
      if (app.$device.isDesktopOrTablet && !isCitySelected()) {
        openCityPickModal()
      }
    }
    selectedCityAnonymous.value = city?.attributes?.name
  }
})
watch(
  () => uiStore.isCityPickerPopupVisible,
  val => {
    if (!process.client) return
    const handle = e => e.stopPropagation()
    if (val) {
      document.body.classList.add('overflow-hidden')
      document.body.addEventListener('touchmove', handle)
    } else {
      document.body.classList.remove('overflow-hidden')
      document.body.removeEventListener('touchmove', handle)
    }
  },
)

return { __sfc: true,app, customerStore, uiStore, cities, isLoggedIn, cookieCity, customerSelectedCity, selectedCityLoggedIn, selectedCityAnonymous, selectedCity, openCityPickModal, isCitySelected }
}

}