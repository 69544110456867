import {
  assignFilter,
  chunk,
  createMPBreadCrumbsCategory,
  createMPCategoriesMenuBreadCrumbs,
  getURLParamsForProducts,
  LOG_ERROR,
  normalizeMPFiltersFacets,
  normalizeMPMenuCategories,
  normalizeMPShortcutCategories,
  normalizeNewCatalogProductItems,
  serialize,
  uniqBy,
} from '@/utilities'
import { INCLUDE_FIELDS } from '@/constants'
import { useUiStore } from '@/stores/ui'
import { useGrosseryStore } from '@/stores/grossery'
import { useCheckoutStore } from '@/stores/checkout'
import { useCheckoutCredit } from '@/stores/checkout_credit'
import { useCustomer } from '@/stores/customer'
import { useMarketStore } from '@/stores/market/index'

export default {
  setPreOrderProduct(payload) {
    this.preOrderPorduct.name = payload
  },
  resetProfileAddresses() {
    this.profileAddresses = []
  },
  resetState(states_for_reset) {
    states_for_reset.map(item => {
      this[item.key] = item.value
    })
  },
  async getLastPudoPoint() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: { data: pudo },
      } = await this.$nuxt.$axios.get('/customers/pudo-points')
      if (pudo?.id) this.last_pudo = pudo
    } catch (e) {
      console.log('Error: getLastPudoPoint', e)
    }
  },
  setRateArgumentsFilter(payload) {
    this.rateArgumentsFilter = {
      ...this.rateArgumentsFilter,
      ...payload,
    }
  },
  async getPudo(point_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.get(`/pudo-points/${point_id}`)
    } catch (e) {
      console.log('Error: getPudo', e)
    }
  },
  async getBrandSeoInfo(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.SEO_SERVICE_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.SEO_SERVICE_BASE_URL
      }
      const {
        data: { data = [] },
      } = await this.$nuxt.$axios.get(
        `/api/v2/brands/seo?slug=${options?.slug}`,
      )
      this.brand = data[0]
    } catch (e) {
      console.log('Error: getBrandSeoInfo', e)
    }
  },
  async getPudoCities() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
      }
      const {
        data: { data = [], status },
      } = await this.$nuxt.$axios.get('/pudo-cities?azerpoct=false')
      if (status === 'success') this.pudo_cities = data
    } catch (e) {
      console.log('Error: getPudoCities', e)
    }
  },
  async getAllPudos(options = {}) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('v1', 'v2')
      }
      const params = new URLSearchParams()
      params.append('azerpoct', 'false')
      params.append('ignore_availability', 'true')
      Object.entries(options).forEach(([key, value]) => {
        params.append(key, String(value))
      })
      const {
        data: { data = [], status },
      } = await this.$nuxt.$axios.get(`/pudo-points?${params.toString()}`)
      if (status === 'success') this.pudos_all = data
    } catch (e) {
      console.log('Error: getAllPudos', e)
    }
  },
  setAddress(payload) {
    const address = {}
    address[payload.orderId] = payload.address || ''
    this.address = { ...this.address, ...address }
  },
  async GET_AZERPOCT_PUDO_CITIES() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      const res = await this.$nuxt.$axios.get('/pudo-cities?azerpoct=true')
      const items = res?.data?.data || []
      this.azerpoctPudoCities = items.filter(item => item.enabled)
      return res
    } catch (e) {
      console.log('ERROR:GET_AZERPOCT_PUDO_CITIES', e)
    }
  },
  async GET_PUDOS_FOR_FAQ({ city_id }) {
    this.pudoListLoading = true
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const options = new URLSearchParams()
      options.append('city_id', city_id)
      options.append('azerpoct', true)
      options.append('state', 'ACTIVE')
      const {
        data: { data: pudos },
      } = await this.$nuxt.$axios.get(`/pudo-points?${options.toString()}`)

      this.faqPudosList = pudos || []
    } catch (e) {
      console.log('ERROR:GET_PUDOS_FOR_FAQ', e)
    } finally {
      this.pudoListLoading = false
    }
  },
  async GET_MERCHANT_NAME_BY_ID({ id, options }) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_SEARCH_BASE_URL ||
        this.$nuxt.$config.SEARCH_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.SEARCH_BASE_URL
    }
    const searchParams = new URLSearchParams()
    for (const key of Object.keys(options)) {
      searchParams.append(key, options[key])
    }
    try {
      const res = await this.$nuxt.$axios.get(
        `v2/marketing_names/${id}?${searchParams.toString()}`,
      )
      this.merchantName = res?.data?.name || null
      return res
    } catch (e) {
      console.log('ERROR:GET_MERCHANT_NAME_BY_ID', e)
    }
  },
  async GET_ANONYMOUS_CART_ACTIVE_CHILD() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    }
    const cart_id = this.$nuxt.$auth.$storage.getLocalStorage('cart_id')
    const response = await this.$nuxt.$axios
      .get(`/anonymous-carts/${cart_id}/active-child`)
      .catch(() => {
        return false
      })
    const data = response?.data?.data
    if (data) {
      this.$nuxt.$auth.$storage.setLocalStorage('cart_id', data.id)
      return true
    } else {
      return false
    }
  },
  async SEND_NOTIFICATION_REPORT_AVAILABILITY(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    await this.$nuxt.$axios.post('/notification', options)
  },
  async SEND_PREORDER_PRODUCT(payload) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data, status } = await this.$nuxt.$axios.post(
        '/pre-orders',
        payload,
      )
      if (status === 200) this.showPreOrderConfirmPopup = true
      return data
    } catch (e) {
      console.log('Error SEND_PREORDER_PRODUCT', e)
    }
  },
  async getSelectiveProducts(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    try {
      const params = new URLSearchParams()
      Object.entries(options).forEach(([key, value]) => {
        params.append(key, String(value))
      })
      const {
        data: { products = [] },
      } = await this.$nuxt.$axios.get(
        `/api/v1/products/blocks/mega-discounts?${params.toString()}`,
      )
      return normalizeNewCatalogProductItems(products)
    } catch (e) {
      console.log('Error: getSelectiveProducts', e)
    }
  },
  async getCategoryPromoProducts(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    try {
      const params = new URLSearchParams()
      Object.entries(options).forEach(([key, value]) => {
        if (key !== 'category_id') params.append(key, String(value))
      })
      const {
        data: { products = [] },
      } = await this.$nuxt.$axios.get(
        `/api/v1/categories/${
          options?.category_id
        }/blocks/mega-discounts?${params.toString()}`,
      )
      return normalizeNewCatalogProductItems(products)
    } catch (e) {
      console.log('Error: getCategoryPromoProducts', e)
    }
  },
  async updateProfileAddress({ address, id }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.patch(`/addresses/${id}`, address)
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'UPDATE_PROFILE_ADDRESS',
        e,
        {
          address,
          id,
        },
      )
    }
  },
  async deleteProfileAddress(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.delete(`/addresses/${id}`)
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'DELETE_PROFILE_ADDRESS',
        e,
        {
          id,
        },
      )
    }
  },
  setPopularCategories(payload) {
    this.popularCategoriesList = payload.splice(0, 12)
  },
  async getPopularCategories() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }

      const { data } = await this.$nuxt.$axios
        .get(`/api/v1/categories/blocks/popular`)
        .catch(e => {
          console.log(e)
        })
      this.setPopularCategories(data)
      return data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_POPULAR_CATEGORIES',
        e,
      )
    }
  },
  setPaymentCards(payload) {
    this.paymentCards = payload
  },
  async getPatmentCards() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data } = await this.$nuxt.$axios.get('/payments/cards')
      this.paymentCards =
        [...[{ masked_pan: 'new_card', token_id: 0 }], ...data?.data] || []
      return data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_PAYMENT_CARDS',
        e,
      )
    }
  },
  setProductDiscountQuantity(payload) {
    this.productDiscountPrice = payload.productDiscountPrice
    this.productDiscountQuantity = (payload && payload.quantity) || 1
    this.productQtyDiscountRule = (payload && payload.rule) || null
  },
  async getProductDiscountQuantities(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const {
        data: { qty_discount_rules },
      } = await this.$nuxt.$axios.get(
        `/v3/market/qty_discount_rules?${serialize(options)}`,
      )
      this.productQtyDiscountRuleItems =
        qty_discount_rules?.[0]?.qty_discount_rule_items || []
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_PRODUCT_DISCOUNT_QUANTITIES',
        e,
        options,
      )
    }
  },
  async setEventEsputnik(data) {
    try {
      function utf8_to_b64(str) {
        return window.btoa(unescape(encodeURIComponent(str)))
      }

      const auth = 'competo:14BADA71352AF5740757094E1F3738E2'
      const set_auth = utf8_to_b64(auth)
      const HTTP = this.$nuxt.$axios.create({
        baseURL: 'https://esputnik.com',
        transformRequest: [
          (data, headers) => {
            headers.Authorization = `Basic ${set_auth}`
            return data
          },
        ],
      })
      await HTTP.post('/api/v1/event', data)
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'SET_EVENT_ESPUTNIK',
        e,
        data,
      )
    }
  },
  async updateCustomerInOrderPackage({ orders_package_id, data }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.put(
        `/dashboard/orders-packages/${orders_package_id}/customer`,
        data,
      )
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'UPDATE_CUSTOMER_IN_ORDER_PACKAGE',
        e,
        { orders_package_id, data },
      )
    }
  },
  setProfileAddresses(address) {
    this.profileAddresses = address.map(item => {
      item.edit = false
      return item
    })
  },
  async getProfileAddresses() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: { data: addresses = [] },
      } = await this.$nuxt.$axios.get(`/addresses`)
      this.setProfileAddresses(addresses)
      return addresses
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_PROFILE_ADDRESSES',
        e,
      )
    }
  },
  async getOrderFillPreferences() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: {
          data: { address = {} },
        },
      } = await this.$nuxt.$axios.get(`/order-fill-preferences`)
      this.orderFillPreferences = address
      return address
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_ORDER_FILL_PREFERENCES',
        e,
      )
    }
  },
  async buyOneClick(product, loggedIn) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const res = await this.$nuxt.$axios.post(
        !loggedIn
          ? '/anonymous/orders-packages/one-click'
          : '/orders-packages/one-click',
        product,
      )
      return res
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'BUY_ONE_CLICK',
        e,
        product,
      )
    }
  },
  async getProductsBestsellers({ options }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const response = await this.$nuxt.$axios
        .get(`/api/v1/products/blocks/bestsellers`)
        .catch(e => {
          console.log(e)
        })

      this.productsBestsellers = normalizeNewCatalogProductItems(
        response?.data?.products || [],
      )
      return response?.data || []
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'getProductsBestsellers',
        e,
        {
          options,
        },
      )
    }
  },
  async addProfileAddress(address) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.post(`/addresses`, address)
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'ADD_PROFILE_ADDRESS',
        e,
        {
          address,
        },
      )
    }
  },
  async getTranslationGoogleAPIS(desc_data) {
    try {
      const info = {
        q: `${desc_data.description}`,
        source: desc_data.source,
        target: desc_data.target,
        format: 'html',
      }
      const HTTP = this.$nuxt.$axios.create({
        baseURL:
          'https://translation.googleapis.com/language/translate/v2?key=AIzaSyCOAu4dDNh4CMLeugy4mLoSt_oXMSquyXM',
        transformRequest: [
          (data, headers) => {
            headers['Accept-Language'] = this.$nuxt.i18n.locale
            return data
          },
        ],
      })
      delete HTTP.defaults.headers.common.Authorization
      const {
        data: { data = {} },
      } = await HTTP.post('', info)
      return data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_TRANSLATION_GOOGLEAPIS',
        e,
        desc_data,
      )
    }
  },
  async getMinCheckoutCartAmount() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: {
          data: { value = {} },
        },
      } = await this.$nuxt.$axios.get(
        `/default-settings/MIN_CHECKOUT_CART_AMOUNT`,
      )
      if (value?.number) {
        this.minOrderAmount = value?.number
      }
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_MIN_CHECKOUT_CART_AMOUNT',
        e,
      )
    }
  },
  async getRepeatOrderInfo(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data } = await this.$nuxt.$axios.post(
        `/orders-packages/repeat-order/check`,
        {
          order_id: id,
        },
      )
      this.repeatOrderInfo = data?.data
      return data?.data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_REPEAT_ORDER_INFO',
        e,
        {
          id,
        },
      )
    }
  },
  async getRepeatOrder(id) {
    const mode = 'REPEAT_ORDER'
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data } = await this.$nuxt.$axios.post(
        `/orders-packages/repeat-order`,
        {
          order_id: id,
        },
      )
      data.data.orders[0].payment_method = this.order?.payment_method
      this.$nuxt.$auth.$storage.setLocalStorage(
        'order_package_id',
        data?.data?.id,
      )
      return data?.data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_REPEAT_ORDER',
        e,
        { id },
      )
    }
  },
  setOrders(payload) {
    this.orders = payload.sort((a, b) => {
      const keyA = new Date(a.created_at)
      const keyB = new Date(b.created_at)
      if (keyA > keyB) return -1
      if (keyA < keyB) return 1
      return 0
    })
  },
  async getOrders(pagination) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/orders?${serialize(pagination)}`,
      )
      this.ordersPagination = data?.meta
      if (pagination?.page === 1) {
        this.setOrders(data?.data)
      } else if (pagination?.page && pagination?.page > 1) {
        const list_orders = this.orders
        list_orders.push(...data?.data)
        this.setOrders(list_orders)
      }
    } catch (e) {
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'GET_ORDERS', e)
    }
  },
  async getOrder({ order_id, loggedIn }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      let urlOrder = `/orders/${order_id}`
      if (!loggedIn) {
        urlOrder = `/anonymous/orders/${order_id}`
      }
      const { data } = await this.$nuxt.$axios.get(urlOrder)
      this.order = data?.data
    } catch (e) {
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'GET_ORDER', e, {
        order_id,
      })
    }
  },
  async cancelOrder({ order_id, payload }) {
    try {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      const { data } = await this.$nuxt.$axios.put(
        `/orders/${order_id}/state`,
        payload,
      )
      this.order = data.data
      return data
    } catch (e) {
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'CANCEL_ORDER')
    }
  },
  async getPaymentInfoByOrder(order_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.get(`/orders/${order_id}/payment-info`)
    } catch (e) {
      console.log('Error getPaymentInfoByOrder', e)
      return { response: e?.response }
    }
  },
  async getTagById(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data = {} } = await this.$nuxt.$axios.get(`/api/v1/tags/${id}`)
      return data
    } catch (e) {
      console.log('Error getTagById', e)
    }
  },
  async getTagBySlug(slug) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    try {
      const { data = {} } = await this.$nuxt.$axios.get(
        `/api/v1/tags/slugged/${slug}`,
      )
      this.tag = data
    } catch (e) {
      console.log('Error getTagBySlug', e)
    }
  },
  async getTagSeoInfoById(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data = {} } = await this.$nuxt.$axios.get(
        `/api/v1/seo/tags/${id}`,
      )
      this.tag = { ...this.tag, ...data }
      return data
    } catch (e) {
      console.log('Error getTagSeoInfoById', e)
    }
  },
  async getPartnersMerchantOffers(uuid) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      await this.$nuxt.$axios.get(`/partners/${uuid}`).then(res => {
        if (res.data) {
          this.partnersMerchantOfficeSchedule = res.data
            ? res.data.data.schedule
            : null
        }
      })
    } catch (e) {
      console.log('Error getPartnersMerchantOffers', e)
    }
  },
  async getMegaDiscountProducts(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const set_url = new URLSearchParams(options)
      const response = await this.$nuxt.$axios.get(
        `/api/v1/products/blocks/mega-discounts?${set_url.toString()}`,
      )
      const newProducts = normalizeNewCatalogProductItems(
        response?.data?.products || [],
      )
      const totalItems = response?.data?.meta?.total || 0
      return { newProducts, totalItems }
    } catch (e) {
      console.log('Error getMegaDiscountProducts', e)
      return { newProducts: [] }
    }
  },
  updateMegaDiscountProducts({ startIndex, items, totalSlideCount }) {
    // Step 1: Replace placeholders with the fetched items
    items.forEach((item, index) => {
      this.megaDiscountProducts[startIndex + index] = item
    })

    // Step 2: Handle the placeholder adjustment based on the totalSlideCount
    const currentLength = this.megaDiscountProducts.length
    const placeholdersNeeded = totalSlideCount - currentLength

    if (placeholdersNeeded > 0) {
      // If more placeholders are needed, add them
      this.megaDiscountProducts = [
        ...this.megaDiscountProducts,
        ...Array(placeholdersNeeded).fill({ placeholder: true }),
      ]
    } else if (placeholdersNeeded < 0) {
      // If fewer placeholders are needed, remove the excess
      // We only want to remove placeholders, not actual items
      let placeholdersToRemove = -placeholdersNeeded
      for (let i = currentLength - 1; i >= 0 && placeholdersToRemove > 0; i--) {
        if (this.megaDiscountProducts[i].placeholder) {
          this.megaDiscountProducts.pop() // Remove from the end
          placeholdersToRemove--
        }
      }
    }

    // Ensure the total length matches the totalSlideCount
    this.megaDiscountProducts = this.megaDiscountProducts.slice(
      0,
      totalSlideCount,
    )
  },
  setInitialMegaDiscountPlaceholders(count) {
    if (this.megaDiscountProducts.length === 0) {
      this.megaDiscountProducts = Array(count).fill({ placeholder: true })
    }
  },
  setMegaDiscountProducts(items) {
    items.forEach((item, index) => {
      this.megaDiscountProducts[index] = item
    })
  },
  async getNewItemsProductsMainPage(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const set_url = new URLSearchParams(options)
      const response = await this.$nuxt.$axios.get(
        `/api/v1/products/blocks/new-arrivals?${set_url.toString()}`,
      )
      const newProducts = normalizeNewCatalogProductItems(
        response?.data?.products || [],
      )
      const totalItems = response?.data?.meta?.total || 0
      return { newProducts, totalItems }
    } catch (e) {
      console.log('Error getNewItemsProductsMainPage', e)
      return { newProducts: [], totalItems: 0 }
    }
  },

  updateNewItemsPopularMainPage({ startIndex, items, totalSlideCount }) {
    try {
      // Step 1: Replace placeholders with the fetched items
      items.forEach((item, index) => {
        this.newItemsPopularMainPage[startIndex + index] = item
      })

      // Step 2: Handle the placeholder adjustment based on the totalSlideCount
      const currentLength = this.newItemsPopularMainPage.length
      const placeholdersNeeded = totalSlideCount - currentLength

      if (placeholdersNeeded > 0) {
        // If more placeholders are needed, add them
        this.newItemsPopularMainPage = [
          ...this.newItemsPopularMainPage,
          ...Array(placeholdersNeeded).fill({ placeholder: true }),
        ]
      } else if (placeholdersNeeded < 0) {
        // If fewer placeholders are needed, remove the excess
        // We only want to remove placeholders, not actual items
        let placeholdersToRemove = -placeholdersNeeded
        for (
          let i = currentLength - 1;
          i >= 0 && placeholdersToRemove > 0;
          i--
        ) {
          if (this.newItemsPopularMainPage[i].placeholder) {
            this.newItemsPopularMainPage.pop() // Remove from the end
            placeholdersToRemove--
          }
        }
      }

      // Ensure the total length matches the totalSlideCount
      this.newItemsPopularMainPage = this.newItemsPopularMainPage.slice(
        0,
        totalSlideCount,
      )

      // Resolve the promise to indicate completion
    } catch (error) {
      // If any error occurs, reject the promise
      console.log('Error getNewItemsPopularMainPage', error)
    }
  },

  setInitialPlaceholders(count) {
    if (this.newItemsPopularMainPage.length === 0) {
      this.newItemsPopularMainPage = Array(count).fill({ placeholder: true })
    }
  },
  setNewItemsPopularMainPage(items) {
    items.forEach((item, index) => {
      this.newItemsPopularMainPage[index] = item
    })
  },
  async getPersonalRecommendationsProducts(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    const params = new URLSearchParams()
    params.append('page', String(options?.page))
    params.append('per_page', String(options?.per_page))
    try {
      const {
        data: { products, meta },
      } = await this.$nuxt.$axios.get(
        `/api/v1/products/blocks/personal-recommendations?${params.toString()}`,
      )
      this.personalRecommendations = normalizeNewCatalogProductItems(products)
      this.personalRecommendationsMeta = {
        page: +options?.page,
        per_page: options?.per_page,
        total_entries: meta?.total,
        total_pages: Math.ceil(+meta?.total / +options?.per_page),
      }
    } catch (e) {
      console.log('Error: getPersonalRecommendationsProducts', e)
    }
  },
  async getSaleDayProducts(pageNumber) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }

      const set_url = new URLSearchParams({ page: pageNumber })
      const response = await this.$nuxt.$axios
        .get(`/api/v1/products/blocks/sale-of-day?${set_url.toString()}`)
        .catch(e => {
          console.log(e)
        })
      const saleDayProducts = normalizeNewCatalogProductItems(
        response?.data?.products || [],
      )
      const totalItems = response?.data?.meta?.total || 0

      return { saleDayProducts, totalItems }
    } catch (e) {
      console.log('Error mainPageBestsellers', e)
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'mainPageBestsellers',
        e,
      )
    }
  },

  updateSaleDayProducts({ startIndex, items, totalSlideCount }) {
    try {
      // Step 1: Replace placeholders with the fetched items
      items.forEach((item, index) => {
        this.saleDayProducts[startIndex + index] = item
      })

      // Step 2: Handle the placeholder adjustment based on the totalSlideCount
      const currentLength = this.saleDayProducts.length
      const placeholdersNeeded = totalSlideCount - currentLength

      if (placeholdersNeeded > 0) {
        // If more placeholders are needed, add them
        this.saleDayProducts = [
          ...this.saleDayProducts,
          ...Array(placeholdersNeeded).fill({ placeholder: true }),
        ]
      } else if (placeholdersNeeded < 0) {
        // If fewer placeholders are needed, remove the excess
        // We only want to remove placeholders, not actual items
        let placeholdersToRemove = -placeholdersNeeded
        for (
          let i = currentLength - 1;
          i >= 0 && placeholdersToRemove > 0;
          i--
        ) {
          if (this.saleDayProducts[i].placeholder) {
            this.saleDayProducts.pop() // Remove from the end
            placeholdersToRemove--
          }
        }
      }

      // Ensure the total length matches the totalSlideCount
      this.saleDayProducts = this.saleDayProducts.slice(0, totalSlideCount)
    } catch (error) {
      // If any error occurs, reject the promise
      console.log('Error updateSaleDayProducts', error)
    }
  },

  setInitialPlaceholdersSaleDay(count) {
    if (this.saleDayProducts.length === 0) {
      this.saleDayProducts = Array(count).fill({ placeholder: true })
    }
  },
  async getAllSaleDayProducts(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const productsParams = getURLParamsForProducts(options)
      const {
        data: { products, meta },
      } = await this.$nuxt.$axios.get(
        `/api/v1/products?${productsParams.toString()}`,
      )

      this.saleDayProductsAll = normalizeNewCatalogProductItems(products) || []
      this.saleDayProductsMeta = {
        page: +options?.page,
        per_page: options?.per_page,
        total_entries: meta?.total,
        total_pages: Math.ceil(+meta?.total / +options?.per_page),
      }
      return products
    } catch (e) {
      console.log('Error getAllSaleDayProducts', e)
      return []
    }
  },
  setSaleDayProducts(items) {
    items?.forEach((item, index) => {
      this.saleDayProducts[index] = item
    })
  },

  async getSeoTags(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/v3/market/tags/frequent?${serialize(options)}`,
      )
      const seoTags = data.data || data?.tags || []
      this.seoTags = (seoTags || []).map(tag => ({
        name: tag.name,
        slugged_name_ru: tag.slugged_name_ru,
        slugged_name_az: tag.slugged_name_az,
        volume: tag.volume,
      }))
      return seoTags
    } catch (e) {
      console.log('Error getSeoTags', e)
    }
  },
  async getMerchantPaymentMethods(partner_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/partners/${partner_id}/payment-methods`,
      )
      this.merchantPaymentMethods = data?.data || []
      return data
    } catch (e) {
      console.log('Error getMerchantPaymentMethods', e)
    }
  },
  async getOffersPaymentMethods(partner_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.get(`/partners/${partner_id}/payment-methods`)

      this.listOffersPaymentMethods = {
        ...this.listOffersPaymentMethods,
        [partner_id]: data,
      }
    } catch (e) {
      console.log('Error getOffersPaymentMethods', e)
    }
  },
  async addReview(review) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
          this.$nuxt.$config.ASSESSMENT_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.ASSESSMENT_API_URL
      }
      const { data } = await this.$nuxt.$axios.post(`/api/v1/session`, review)
      return data
    } catch (e) {
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'ADD_REVIEW', e, {
        review,
      })
    }
  },
  async checkOperation(operation_ext_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
          this.$nuxt.$config.ASSESSMENT_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.ASSESSMENT_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/check-operation/${operation_ext_id}`,
      )
      return data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'CHECK_OPERATION',
        e,
        {
          operation_ext_id,
        },
      )
    }
  },
  async getOfferReviews(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
        this.$nuxt.$config.ASSESSMENT_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.ASSESSMENT_API_URL
    }
    const response = await this.$nuxt.$axios.get(
      `/api/v1/public/message-counts?${serialize(options)}`,
    )
    this.listOffersReviews = response?.data
    return ''
  },
  setCompanyReviews({ data, loadmore }) {
    if (loadmore) {
      this.companyReviews.messages = [
        ...this.companyReviews.messages,
        ...data.messages,
      ]
      this.companyReviews.offset = data.offset
    } else {
      this.companyReviews = data
    }
  },
  async getCompanyReviews(options) {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
        this.$nuxt.$config.ASSESSMENT_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.ASSESSMENT_API_URL
    }
    const loadmore = options?.loadmore || false
    delete options?.loadmore
    let uri = ''
    if (
      customerStore.isLoggedIn &&
      this.$nuxt.$auth.$storage.getCookie('user_ext_id')
    ) {
      uri = 'message'
    } else {
      uri = 'public/message'
    }
    return await this.$nuxt.$axios
      .get(`/api/v1/${uri}?${serialize(options)}`)
      .then(({ data }) => {
        this.setCompanyReviews({
          data,
          loadmore,
        })
      })
      .catch(e => {
        LOG_ERROR(
          this.$nuxt?.$rollbar,
          this.$nuxt?.$config,
          'GET_COMPANY_REVIEWS',
          e,
          options,
        )
      })
  },

  async getMainTopSeoTags(options = {}) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const res = await this.$nuxt.$axios.get(
        `/v3/market/tags?${serialize(options)}`,
      )
      const { data } = res
      this.mainTopSeoTags = data?.tags
      return res
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'GET_MAIN_TOP_SEO_TAGS',
        e,
        options,
      )
    }
  },
  async getTagsLinks(options = {}) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const { data = {} } = await this.$nuxt.$axios.get(
        `/v3/market/tags?${serialize(options)}`,
      )
      return data
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'GET_TAGS_LINKS',
        e,
        options,
      )
    }
  },
  async getComplementaryProducts(product_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/products/${product_id}/complementaries`,
      )
      const products = normalizeNewCatalogProductItems(data || [])
      return products || []
    } catch (err) {
      console.log('Error getComplementaryProducts', err)
      return []
    }
  },
  async getProductSeeAlsoUpdated(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const params = new URLSearchParams()
      params.append('page', String(options.page + 1))
      params.append('per_page', String(options.per_page))
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/categories/${
          options?.category_id
        }/blocks/see-also?${params.toString()}`,
      )
      const products = normalizeNewCatalogProductItems(data?.products || [])
      return { products, meta: data.meta }
    } catch (err) {
      console.log('Error getProductSeeAlsoUpdated', err)
      throw err
    }
  },
  async getProductSeeAlso({ category_id_eq, product_id }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const options = {
        product_id,
        category_id_eq,
        response_mode: 'see_also_products',
      }
      const res = await this.$nuxt.$axios.get(
        `/v1/seo/faqs?${serialize(options)}`,
      )
      const {
        data: { see_also_products = [] },
      } = res
      this.productSeeAlso = see_also_products
      return res
    } catch (e) {
      console.log('Error loading GET_PRODUCT_SEE_ALSO', e)
      throw e
    }
  },
  setStockOffersProduct(options) {
    this.stockOffersProducts = options
  },
  async getStockOffersProduct(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
    }
    const {
      data: { data },
    } = await this.$nuxt.$axios.get(
      `/v3/market/products/mget?${serialize(options)}`,
    )
    this.stockOffersProducts = data
    return data
  },
  async getAlternativeProducts(location) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      let outOfStockProducts
      if (location === 'cart') {
        outOfStockProducts = this.stockOffersProducts.filter(
          product => product.status === 'out_of_stock',
        )
      } else if (location === 'favorites') {
        outOfStockProducts = this.favoriteProducts.filter(
          product => product.status === 'out_of_stock',
        )
      }
      const ids = outOfStockProducts
        .map(item => item.alternative_products.map(item => item.product_id))
        .flat()
      if (ids.length) {
        const options = {
          include_fields: `${INCLUDE_FIELDS},categories,default_offer_allow_qty,product_labels,default_discount_effective_start_date,default_discount_effective_end_date,discounted,offers.installment_enabled,offers.max_installment_months,offers.avail_check,offers.old_price,min_qty`,
          'q[id_in]': ids.join(','),
        }
        const data = await this.getProductById({ options })
        if (location === 'cart') {
          this.alternativeProductsCart = data
        }
        if (location === 'favorites') {
          this.alternativeProductsFavorites = data
        }
      }
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'GET_ALTERNATIVE_PRODUCTS', e)
    }
  },
  setProductById(payload) {
    const newArray = []
    payload.map(item => {
      if (!this.productsById.includes(item)) {
        newArray.push(item)
      }
    })
    const _toUpdate = [...this.productsById, ...newArray]
    this.productsById = uniqBy(_toUpdate, function (e) {
      return e.id
    })
  },
  resetProductsById() {
    this.productsById = []
  },
  async getProductById({ options, local = false }) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
    }
    try {
      const { data } = await this.$nuxt.$axios.get(
        `/v3/market/products/mget?${serialize(options)}`,
      )

      if (!local) {
        this.setProductById(data?.data || [])
      }

      return data?.data || []
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'GET_PRODUCTS_BY_ID', e, {
        options,
        local,
      })
    }
  },
  async getAnonFavorites(options = {}) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    try {
      const params = new URLSearchParams()
      Object.entries(options).forEach(([key, value]) => {
        params.append(key, String(value))
      })
      const customer_id =
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id')
      const {
        data: { products = [] },
      } = await this.$nuxt.$axios.get(
        `/api/v1/favorites/${customer_id}?${params.toString()}`,
      )
      if (options?.page > 1) {
        this.anonFavorites = [
          ...this.anonFavorites,
          ...normalizeNewCatalogProductItems(products),
        ]
      } else {
        this.anonFavorites = normalizeNewCatalogProductItems(products)
      }
      return products
    } catch (e) {
      console.log('Error: getAnonFavorites', e)
    }
  },
  async addToAnonFavorites(payload) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_WISH_LIST_API_URL.replace('v1', 'v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
    }
    try {
      const customer_id =
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id')
      await this.$nuxt.$axios.post(
        `/favorites/anonymous/${customer_id}`,
        payload,
      )
      this.anonFavoriteList.unshift(payload?.product_id)
      this.anonFavoritesCount = this.anonFavoriteList.length
    } catch (e) {
      console.log('Error: addToAnonFavorites', e)
    }
  },
  async removeFromAnonFavorites(product_id) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_WISH_LIST_API_URL.replace('v1', 'v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
    }
    try {
      const customer_id =
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id')
      await this.$nuxt.$axios.delete(
        `/favorites/anonymous/${customer_id}/${product_id}`,
      )
      this.anonFavorites = this.anonFavorites.filter(
        item => item.id !== product_id,
      )
      this.anonFavoriteList = this.anonFavoriteList.filter(id => {
        return id !== product_id
      })
      this.anonFavoritesCount = this.anonFavoriteList.length
    } catch (e) {
      console.log('Error: removeFromAnonFavorites', e)
    }
  },
  async setAnonFavoriteProductsMultiple(list_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.post(
        `/favorites/line-items/multiple`,
        list_id,
      )
      this.$nuxt.$auth.$storage.setLocalStorage('favorite_list', [])
      this.$nuxt.$auth.$storage.setLocalStorage(
        'favorite_list_data',
        JSON.stringify({}),
      )
      this.anonFavoriteList = []
      return data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        'SET_ANON_FAVORITE_PRODUCTS_MULTIPLE',
        e,
        list_id,
      )
    }
  },
  setFavoriteProducts(payload) {
    const favorite_products = []
    if (payload?.length) {
      payload?.map(product => {
        favorite_products.push(product)
      })
      this.favoriteProducts = favorite_products
    } else {
      this.favoriteProducts = []
    }
  },
  addFavoriteProducts(payload) {
    if (payload?.length) {
      payload?.map(product => {
        this.favoriteProducts.push(product)
      })
    }
  },
  async getFavoriteProducts(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    try {
      const params = new URLSearchParams()
      Object.entries(options).forEach(([key, value]) => {
        params.append(key, String(value))
      })
      const {
        data: {
          products,
          meta: { total },
        },
      } = await this.$nuxt.$axios.get(`/api/v1/favorites?${params.toString()}`)
      if (options.page === 1) {
        this.setFavoriteProducts(normalizeNewCatalogProductItems(products))
      } else {
        this.addFavoriteProducts(normalizeNewCatalogProductItems(products))
      }
      this.favoritesCount = total
    } catch (e) {
      console.log('Error: getFavoriteProducts', e)
    }
  },
  setFavoriteUUIDS(payload) {
    const favoriteUUIDS = []
    const favorites = {}
    if (payload?.line_items) {
      payload?.line_items.map(lineItem => {
        favoriteUUIDS.push(lineItem.product_id)
        favorites[`${lineItem.product_id}`] = lineItem.id
      })
      this.favoriteUUIDS = favoriteUUIDS
      this.favorites = favorites
      this.favoritesCount = favoriteUUIDS.length
    } else if (Array.isArray(payload) && payload.length) {
      this.favoriteUUIDS = payload
      this.favoritesCount = this.favoriteUUIDS.length
    } else {
      this.favoriteUUIDS = []
      this.favorites = {}
    }
  },
  async removeFromFavorites(params) {
    this.setBlockScrollFavoritesProducts(true)
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL.replace('v1', 'v2') ||
          this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
      }

      await this.$nuxt.$axios.delete(`/favorites/authorized/${params.id}`)

      const favorite_uuids = this.favoriteUUIDS

      if (this.favoriteProducts) {
        const list_favorite_products = this.favoriteProducts?.filter(
          product => {
            return product.id != params?.id
          },
        )
        this.setFavoriteProducts(list_favorite_products)
      }
      const new_list_favorite_uuids = favorite_uuids.filter(
        item => item !== params?.id,
      )

      this.favoriteUUIDS = new_list_favorite_uuids

      this.favoritesCount = new_list_favorite_uuids?.length
      setTimeout(() => {
        this.setBlockScrollFavoritesProducts(false)
      }, 100)
    } catch (e) {
      const id = params.id
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'REMOVE_FROM_FAVORITES',
        e,
        {
          id,
        },
      )
    }
  },
  async addToFavorites(payload) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL.replace('v1', 'v2') ||
          this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
      }
      await this.$nuxt.$axios.post(`/favorites/authorized`, payload)
      this.favoriteUUIDS.unshift(payload.product_id)
      this.favoritesCount = this.favoriteUUIDS.length
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'ADD_TO_FAVORITES',
        e,
        {
          payload,
        },
      )
    }
  },
  async getFavorites() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_WISH_LIST_API_URL.replace('v1', 'v2') ||
        this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
    }
    try {
      const customerStore = useCustomer()
      if (customerStore.isLoggedIn) {
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`/favorites/authorized`)
        this.setFavoriteUUIDS(data)
      } else {
        const customer_id =
          this.$nuxt.$auth.$storage.getLocalStorage('customer_id')
        if (!customer_id) {
          this.$nuxt.$auth.$storage.setLocalStorage(
            'customer_id',
            `${Date.now()}`,
          )
        }
        const {
          data: { data = [] },
        } = await this.$nuxt.$axios.get(`/favorites/anonymous/${customer_id}`)
        this.anonFavoriteList = data
        this.anonFavoritesCount = data.length
      }
    } catch (e) {
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'GET_FAVORITES', e)
    }
  },
  // CLEAR_CARD
  clearCard() {
    this.personalRecommendationsProducts = []
    this.favoriteProducts = []
    this.favoriteUUIDS = []
    this.favoritesCount = null
    this.favorites = {}
  },
  async getCategoryById(category_id = null) {
    const lang = this.$nuxt.i18n?.locale || 'az'
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/categories/${category_id}`,
      )
      this.breadcrumbs = createMPBreadCrumbsCategory(data, lang)
      return data
    } catch (e) {
      console.log('Error getCategoryById', e)
    }
  },
  async getSubCategoryById(parent_id = null) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const params = new URLSearchParams()
      params.append('parent_id', `${parent_id}`)
      const {
        data: { categories = [] },
      } = await this.$nuxt.$axios.get(`/api/v1/categories?${params.toString()}`)
      this.listProductCategories[parent_id] = categories
      return categories
    } catch (e) {
      console.log('Error getSubCategoryById', e)
    }
  },
  async getCategorySeoData(category_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/seo/categories/${category_id}`,
      )
      this.categorySeoData = data
    } catch (e) {
      console.log('Error getProductSeoData', e)
    }
  },
  async getBreadcrumbs(categoryId) {
    const lang = this.$nuxt.i18n?.locale || 'az'
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    const { data } = await this.$nuxt.$axios.get(
      `/api/v1/categories/${categoryId}`,
    )
    this.breadcrumbs = createMPBreadCrumbsCategory(data, lang)
  },
  async getSeoFaq(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      let url
      if (options.categoryId) {
        url = `/api/v1/categories/${options.categoryId}/blocks/faq`
      }
      if (options.merchantId) {
        url = `/api/v1/sellers/by-marketing-name-id/${options.merchantId}/blocks/faq`
      }
      const { data } = await this.$nuxt.$axios.get(url)
      return data
    } catch (err) {
      console.log('Error getSeoFaq', err)
      throw err
    }
  },
  async getCategoryShortcuts(options = {}) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data = [] } = await this.$nuxt.$axios.get(
        '/api/v1/categories/shortcuts',
      )
      if (options.location === 'landing_menu') {
        this.categoryShortcuts = normalizeMPShortcutCategories(data)
      }
      if (
        ['categories', 'categories_slider', 'mega-discounts'].includes(
          options.location,
        )
      ) {
        this.categories = normalizeMPShortcutCategories(data)
      }
      return data
    } catch (e) {
      console.log('Error getCategoryShortcuts', e)
    }
  },
  async getProductsByVariantCode(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
    }
    try {
      const { data } = await this.$nuxt.$axios.get(
        `/v3/market/products?${serialize(options)}`,
      )
      const { products = [] } = data
      this.variantCodeProducts = products.sort(a =>
        a.status === 'out_of_stock' ? 1 : -1,
      )
      return products
    } catch (e) {
      console.log('Error GET_PRODUCTS_BY_VARIANT_CODE', e)
    }
  },
  async getProductsWithoutFilters({ options, appendMode }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/v3/market/products?${serialize(options)}`,
      )
      const { products = [], meta = {} } = data
      if (appendMode) {
        this.products = [...this.products, ...products]
      } else {
        this.products = products
      }
      this.pagination = meta
      return products
    } catch (e) {
      console.log('Error GET_PRODUCTS_WITHOUT_FILTERS', e)
    }
  },
  async getPlanDateDelivery(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const res = await this.$nuxt.$axios.get(
        `/partners/${id}/delivery-date-estimation`,
      )
      if (res.data.data.date) {
        return res.data.data.date
      }
      return res
    } catch (err) {
      console.log('Error getPlanDateDelivery', err)
      throw new Error(err)
    }
  },
  setProductCategories(payload) {
    if (payload.id === 'root') {
      this.productCategories.root = payload.data
    } else if (!Object.keys(this.productCategories).includes(payload.id))
      this.productCategories[payload.id] = payload.data
  },
  // setLandingMenuCategories(payload) {
  //   if (payload.id === 'root') {
  //     this.landingProductCategories.root = payload.data
  //   } else if (!Object.keys(this.landingProductCategories).includes(payload.id))
  //     this.landingProductCategories[payload.id] = payload.data
  // },
  setProductCategoriesBreadcrumbs(payload) {
    this.productCategoriesBreadcrumbs = payload
  },
  clearProductCategories() {
    this.productCategoriesBreadcrumbs = []
    this.productCategories = {}
  },
  setLocalFilters(payload) {
    this.localFilters = payload
  },
  async getProductCategories({ parent_id = null, isRoot = false }) {
    const lang = this.$nuxt.i18n.locale
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const {
        data: { categories = [] },
      } = await this.$nuxt.$axios.get('/api/v1/categories')
      this.setProductCategories({
        id: parent_id || 'root',
        data: normalizeMPMenuCategories(categories, lang),
      })
      const selfCategory = isRoot ? null : await this.getCategoryById(parent_id)
      this.setProductCategoriesBreadcrumbs(
        createMPCategoriesMenuBreadCrumbs(selfCategory, parent_id, isRoot),
      )
    } catch (e) {
      console.log('Error getProductCategories', e)
    }
  },
  setAssignFilters() {
    this.localFilters.custom.map(local_filter_item => {
      const reset_local_filter = this.filters.custom.find(
        filter => filter.title === local_filter_item.title,
      )
      if (local_filter_item?.buckets && reset_local_filter?.buckets) {
        assignFilter(local_filter_item, reset_local_filter)
      }
    })
  },
  async getProducts({ options = {}, config = {} }) {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
      const productsParams = getURLParamsForProducts(options)
      let filters = []
      let facets = []
      const {
        data: { products, meta },
      } = await this.$nuxt.$axios.get(
        `/api/v1/products?${productsParams.toString()}`,
      )
      if (config?.filters) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
        const { data = [] } = await this.$nuxt.$axios.get(
          `/api/v1/filters?${productsParams.toString()}`,
        )
        filters = data || []
      }
      if (config?.facets) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
        const { data = [] } = await this.$nuxt.$axios.get(
          `/api/v1/facets?${productsParams.toString()}`,
        )
        facets = data || []
      }
      this.products = normalizeNewCatalogProductItems(products)
      this.pagination = {
        page: +options?.page,
        per_page: options?.per_page,
        total_entries: meta?.total,
        total_pages: Math.ceil(+meta?.total / +options?.per_page),
      }
      this.filters = normalizeMPFiltersFacets(filters, facets, this.$nuxt.i18n)
      if (this.resetLocalFilters) {
        this.setAssignFilters()
      }
      return { products, meta, filters, facets }
    } catch (e) {
      console.log('Error getProducts', e)
    }
  },
  setResetFilters(payload) {
    this.resetLocalFilters = payload
  },
  async getMoreProduct({ options, statuses, page = '' }) {
    try {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
      const productsParams = getURLParamsForProducts(options)
      const {
        data: { products, meta },
      } = await this.$nuxt.$axios.get(
        `/api/v1/products?${productsParams.toString()}`,
      )
      this.products = [
        ...this.products,
        ...normalizeNewCatalogProductItems(products),
      ]
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_MORE_PRODUCTS',
        e,
        {
          options,
          statuses,
          page,
        },
      )
    }
  },
  async getSizeChartInfo(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
    }
    try {
      const { data } = await this.$nuxt.$axios.get(
        '/v3/market/size_charts',
        options,
      )
      const {
        size_chart: {
          values,
          images: { original: imgUrl },
          description,
        },
      } = data
      this.sizeChart = { values, imgUrl, description }
      this.sizeChartAvailable = true
    } catch (e) {
      if (e.response.status === 404) this.sizeChartAvailable = false
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_SIZE_CHART_INFO',
        e,
        {
          options,
        },
      )
    }
  },
  async getProductSeoData(product_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/seo/products/${product_id}`,
      )
      this.productSeoData = data
    } catch (e) {
      console.log('Error getProductSeoData', e)
    }
  },
  async getProductOffers(product_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const {
        data: { offers },
      } = await this.$nuxt.$axios.get(`/api/v1/products/${product_id}/offers`)
      return offers
    } catch (e) {
      console.log('Error getProductOffers', e)
      this.productOffers = []
    }
  },
  async getSimilarProducts(product_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const { data: products } = await this.$nuxt.$axios.get(
        `/api/v1/products/${product_id}/similars`,
      )
      return products || []
    } catch (e) {
      console.log('Error getSimilarProducts', e)
      return []
    }
  },
  async getProductVariations(variant_code) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const {
        data: { products },
      } = await this.$nuxt.$axios.get(
        `/api/v1/products/variation?code=${variant_code}`,
      )
      this.productVariations = products
      return products
    } catch (e) {
      console.log('Error getProductOffers', e)
      this.productVariations = []
    }
  },
  async getProduct(product_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const response = await this.$nuxt.$axios.get(
        `/api/v1/products/${product_id}`,
      )
      this.product = response?.data
      return response
    } catch (e) {
      console.log('Error getProductUpdated', e)
    }
  },
  async mergeCartsAnonUser({ mode = 'DEFAULT' }) {
    const cart_id = this.$nuxt.$auth.$storage.getLocalStorage('cart_id')
    const uiStore = useUiStore()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    let set_response = null
    if (cart_id) {
      this.anonymous_old_cart_amount = this.cart?.total_amount
      const options = {
        cart_id,
        checkout_mode: mode,
      }
      if (uiStore.fromBB) {
        options.origin = 'BIRBANK'
      }
      if (mode === 'CART_LOAN') {
        options.is_test = false
        // options.loan_product = 'KB_LOAN_V2'
        options.loan_process_version = 7
      }
      const GrosseryStore = useGrosseryStore()
      options.checkout_order_mode = GrosseryStore.typeGrosseryCartMode
      const CheckoutStore = useCheckoutStore()
      // const totalCheckout = CheckoutStore?.orderPackage?.total_amount
      try {
        const { data } = await this.$nuxt.$axios.post(
          `/orders-packages`,
          options,
        )
        set_response = data
        this.$nuxt.$auth.$storage.setLocalStorage('cart_id', null)
        const checkoutStore = useCheckoutStore()
        checkoutStore.order_packages = data?.data
        this.$nuxt.$auth.$storage.setLocalStorage(
          'order_package_id',
          data?.data?.id,
        )
        // if (totalCheckout !== data?.data?.total_amount) {
        //   this.$nuxt.store.$router.resolve({
        //     path: this.$nuxt.localePath('cart'),
        //   })
        // }
      } catch (e) {
        this.$nuxt.$auth.$storage.setLocalStorage('cart_id', null)
        LOG_ERROR(
          this.$nuxt?.$rollbar,
          this.$nuxt?.$config,
          'MERGE_CARTS_ANON_USER',
          e,
          options,
        )
        if (e?.response?.status == 500) {
          set_response = 'error'
        }
      }
    }
    return set_response
  },

  setCart(payload) {
    const grosseryStore = useGrosseryStore()
    grosseryStore.grossery_total_amount = 0
    const sellers = payload?.sellers
    const uuids = []
    if (sellers) {
      sellers.map(item => {
        if (item.id === grosseryStore.grossery_merchant_uid) {
          grosseryStore.grossery_total_amount = item.amount
        }
        item.line_items.map(litem => {
          const added = {
            id: litem.product_id,
            offer_id: litem?.offer_id || null,
          }
          uuids.push(added)
        })
      })
      this.cartUUIDS = uuids || []
    } else {
      this.cartUUIDS = []
      this.cart = null
    }

    let count = 0
    if (sellers) {
      sellers.map(seller => {
        seller.line_items.map(item => {
          count += item.quantity
        })
      })
      this.cartCount = count
    } else {
      this.cartCount = 0
      this.cartUUIDS = []
    }

    // TODO fix before upload
    const res = Object.create(payload)
    res.sellers = res?.sellers?.map(seller => {
      return {
        ...seller,
        line_items: seller?.line_items.map(line_items => {
          const setSelectedForCheckout = () => {
            if (line_items.hasOwnProperty('delivery_possible')) {
              return line_items?.delivery_possible
                ? line_items.is_selected_for_checkout
                : false
            } else {
              return line_items.is_selected_for_checkout
            }
          }
          return {
            ...line_items,
            is_selected_for_checkout: setSelectedForCheckout(),
          }
        }),
      }
    })
    // TODO fix before upload
    // this.cart = payload
    // TO DO return this.cart = payload
    this.cart = { ...payload, ...res }
    // TODO change when was FulFillmant
    // state.cart.partners = partners
  },
  async addProductToCart({ product }) {
    const marketStore = useMarketStore()
    const set_product = product?.line_items?.[0]
    marketStore.showMobileCartProduct = {
      ...set_product,
      id: set_product?.product_id,
    }
    try {
      const cart_id = this.$nuxt.$auth.$storage.getLocalStorage('cart_id')
      if (cart_id) {
        await this.addProductToAnonCart({
          cart_id,
          product,
        })
      } else {
        await this.addProductToAnonCart({
          product,
        })
      }
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'ADD_PRODUCT_TO_CART',
        e,
        {
          product,
        },
      )
      throw new Error(e)
    }
  },
  async addProductToAnonCart({ product, cart_id }) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      if (customerStore.isLoggedIn) {
        const { data } = await this.$nuxt.$axios.post(
          `/cart/line-items${
            product?.line_items?.length > 1 ? '/multiple' : ''
          }?delivery_estimation=true`,
          product?.line_items && product?.line_items?.length > 1
            ? { line_items: product?.line_items }
            : product.line_items[0],
        )

        this.setCart(data?.data)
      } else if (cart_id) {
        const { data } = await this.$nuxt.$axios.post(
          `/anonymous-carts/${cart_id}/line-items${
            product?.line_items?.length > 1 ? '/multiple' : ''
          }`,
          product?.line_items && product?.line_items?.length > 1
            ? { line_items: product?.line_items }
            : product.line_items[0],
        )

        this.setCart(data?.data)
      } else {
        const { data } = await this.$nuxt.$axios.post(
          `/anonymous-carts`,
          product,
        )
        if (data?.data?.id) {
          this.$nuxt.$auth.$storage.setLocalStorage('cart_id', data?.data?.id)
        }
        this.setCart(data?.data)
      }
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'ADD_PRODUCT_TO_ANON_CART',
        e,
        {
          product,
          cart_id,
        },
      )
    }
  },
  clearCart() {
    this.cart = null
    this.cartCount = 0
    this.cartUUIDS = []
  },
  async recreateCart() {
    try {
      this.$nuxt.$auth.$storage.setLocalStorage('cart_id', null)
      this.cart = null
      this.cartCount = 0
      this.cartUUIDS = []
      this.orderPackages = null
    } catch (e) {
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'RECREATE_CART', e)
    }
  },
  updateCartProducts(status) {
    if (this.cart && this.cart?.sellers) {
      this.cart?.sellers.forEach(seller => {
        seller.line_items.forEach(product => {
          if (product.delivery_possible) {
            product.is_selected_for_checkout = status
          }
        })
      })
    }
  },
  async getCart(options = {}) {
    const customerStore = useCustomer()
    try {
      // CHECK USER credit BALANCE
      const checkoutCredit = useCheckoutCredit()
      await checkoutCredit.CHECK_USER_CREDIT_BALANCE()
      if (checkoutCredit?.credit_user_balance?.avail_limit_max) {
        options.available_loan_limit =
          checkoutCredit?.credit_user_balance?.avail_limit_max
      }

      options.city_latitude =
        customerStore?.customerCoordinates?.lat || 40.597782
      options.city_longitude =
        customerStore?.customerCoordinates?.lng || 49.674576
      options.delivery_estimation = true
      // CHECK USER credit BALANCE - END
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      const cart_id = this.$nuxt.$auth.$storage.getLocalStorage('cart_id')
      if (customerStore.isLoggedIn) {
        if (this.$nuxt.$auth.$storage.getCookie('isExistingUser')) {
          let url = `/cart`
          if (options) url = `/cart?${serialize(options)}`
          const { data } = await this.$nuxt.$axios.get(url)
          this.$nuxt.$auth.$storage.setLocalStorage(
            'user_cart_id',
            data?.data?.id,
          )
          this.setCart(data?.data)
        } else if (cart_id) {
          await this.$nuxt.$axios
            .get(`/anonymous-carts/${cart_id}?delivery_estimation=true`)
            .then(response => {
              this.setCart(response?.data?.data)
            })
            .catch(error => {
              LOG_ERROR(
                this.$nuxt?.$rollbar,
                this.$nuxt?.$config,
                'GET_CART',
                error,
                {
                  options,
                },
              )
              console.log('ERROR GET_CART', error)
            })
        }
      } else if (cart_id) {
        await this.$nuxt.$axios
          .get(`/anonymous-carts/${cart_id}`)
          .then(response => {
            this.setCart(response?.data?.data)
          })
          .catch(error => {
            console.log('ERROR SET_CART', error)
          })
      }
    } catch (e) {
      console.log('Error - GET_CART', e)
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'GET_CART', e, {
        options,
      })
    }
  },
  async setSelectForCheckout({ line_item_ids }) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }

      const cart_id = this.$nuxt.$auth.$storage.getLocalStorage('cart_id')
      if (customerStore.isLoggedIn) {
        if (this.$nuxt.$auth.$storage.getCookie('isExistingUser')) {
          await this.$nuxt.$axios.patch('/cart/select-for-checkout', {
            line_item_ids,
          })
        } else if (cart_id) {
          await this.$nuxt.$axios.patch(
            `/anonymous-carts/${cart_id}/select-for-checkout`,
            {
              line_item_ids,
            },
          )
        }
      } else if (cart_id) {
        await this.$nuxt.$axios.patch(
          `/anonymous-carts/${cart_id}/select-for-checkout`,
          {
            line_item_ids,
          },
        )
      }
      await this.getCart({})
    } catch (e) {}
  },
  async removeProduct({ item_id }) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      if (customerStore.isLoggedIn) {
        const CheckoutCredit = useCheckoutCredit()
        const set_url = CheckoutCredit?.credit_user_balance?.avail_limit_max
          ? `/cart/line-items/${item_id}?available_loan_limit=${CheckoutCredit?.credit_user_balance?.avail_limit_max}`
          : `/cart/line-items/${item_id}`
        const { data } = await this.$nuxt.$axios.delete(set_url)
        // this.setCart(data?.data)
      } else {
        const { data } = await this.$nuxt.$axios.delete(
          `/anonymous-carts/${this.$nuxt.$auth.$storage.getUniversal(
            'cart_id',
          )}/line-items/${item_id}`,
        )
        // this.setCart(data?.data)
      }
      await this.getCart()
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'REMOVE_PRODUCT',
        e,
        { item_id },
      )
    }
  },
  async setProductQuantity({ quantity, item_id }) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL.replace('/v1', '/v2') ||
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
      }
      const cart_id = this.$nuxt.$auth.$storage.getLocalStorage('cart_id')

      if (customerStore.isLoggedIn) {
        const options = {}
        const CheckoutCredit = useCheckoutCredit()
        if (CheckoutCredit?.credit_user_balance?.avail_limit_max) {
          options.available_loan_limit =
            CheckoutCredit?.credit_user_balance?.avail_limit_max
        }
        const { data } = await this.$nuxt.$axios.patch(
          `/cart/line-items/${item_id}?${serialize(options)}`,
          { quantity },
        )
        // this.setCart(data?.data)
      } else {
        const { data } = await this.$nuxt.$axios.patch(
          `/anonymous-carts/${cart_id}/line-items/${item_id}`,
          {
            quantity,
          },
        )
        // this.setCart(data?.data)
      }
      await this.getCart()
      this.toggleButtonQuantity(false)
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'SET_PRODUCT_QUANTITY',
        e,
        {
          quantity,
          item_id,
        },
      )
    }
  },
  toggleButtonQuantity(payload) {
    this.lockButtonQuantity = payload
  },
  setWarningModalLoanApplicationCreated(payload) {
    this.warningModalLoanApplicationCreated = payload
  },
  setOrderRecipientForUpdate(recipient) {
    this.orderRecipientForUpdate = recipient
  },
  setDeliveryPrice({ method, order_id }) {
    this.deliveryPrice[order_id][method] =
      this.orderDeliveryTariffs?.[order_id]?.[method]?.price
  },
  setOrderPackage({ data: payload, mode }) {
    this.orderPackages = {}
    this.ordersForUpdate = {}
    this.orderRecipientForUpdate = {}
    this.deliveryPrice = {}
    this.orderPackages = payload
    payload?.orders.map(order => {
      let categoryInstallServiceNeeded = false
      order.line_items.forEach(item => {
        if (item.category.install_service_needed) {
          categoryInstallServiceNeeded = true
        }
      })
      const data = {
        currentAddressClarificationShow: false,
        delivery: {
          address: {
            city_id: 1,
            city: order?.shipping?.address?.city || '',
            street: order?.shipping?.address?.street || '',
            building: order?.shipping?.address?.building || '',
            apartment: '',
            precision: '',
            address_lat: '',
            address_lng: '',
            pudo: '',
            pudo_lat: '',
            pudo_lng: '',
            fromMap: false,
            isDragged: false,
          },
          delivery_time: {
            date: order?.shipping?.delivery_time?.date,
            time_frame_code: order?.shipping?.delivery_time?.time_frame_code,
            time_frame_description:
              order?.shipping?.delivery_time?.time_frame_description,
          },
          method: order?.shipping?.method.code,
        },
        payment: {
          method: order?.payment_method?.code,
        },
        valid: false,
        submitted: false,
        total_amount: order?.total_amount,
        category_install_service_needed: categoryInstallServiceNeeded,
        install_service_needed: order?.install_service_needed,
        clarification_call_needed: true,
        price_includes_additional_service:
          order?.price_includes_additional_service,
        promocode: order?.promocode,
        premium_promocode: order?.cashback_promo_code,
        promocode_discount: order?.promocode_discount,
        amount_for_payment: 0,
        amount_for_payment_multiplied: 0,
        number: 0,
        max_installment_months: order?.max_installment_months,
      }
      if (mode === 'REPEAT_ORDER') {
        data.delivery.delivery_time.date = ''
        data.delivery.delivery_time.time_frame_code = ''
        data.delivery.delivery_time.time_frame_description = ''
      }
      // If cart Loan
      if (mode === 'CART_LOAN') {
        const loan_application = JSON.parse(
          JSON.stringify(payload.loan_application),
        )
        loan_application.valid = false
        loan_application.loan_fee = null
        loan_application.credit_bureau_consent = true
        loan_application.imei_code = false
        loan_application.phone_number =
          loan_application.phone_number &&
          loan_application.phone_number.charAt(0) !== '+'
            ? `+${loan_application.phone_number}`
            : loan_application.phone_number

        this.loanApplication = loan_application
      }

      const ordersForUpdate = {}
      ordersForUpdate[order.id] = data
      this.ordersForUpdate = { ...this.ordersForUpdate, ...ordersForUpdate }

      this.orderRecipientForUpdate = {
        email: order?.shipping?.recipient?.email,
        name: order?.shipping?.recipient?.name,
        phone_number: order?.shipping?.recipient?.phone_number,
        surname: order?.shipping?.recipient?.surname,
        note: order?.note,
      }

      const deliveryPrice = {}
      deliveryPrice[order.id] = {
        PUDO: null,
        COURIER_EXPRESS: null,
        COURIER_STANDARD: null,
      }
      this.deliveryPrice = { ...this.deliveryPrice, ...deliveryPrice }
    })
  },
  setOrderPackageToDefault() {
    this.ordersForUpdate = {
      profile: {
        delivery: {
          address: {
            city_id: 1,
            city: '',
            street: '',
            building: '',
            apartment: '',
            precision: '',
            address_lat: '',
            address_lng: '',
            pudo: '',
            pudo_lat: '',
            pudo_lng: '',
            fromMap: false,
            isDragged: false,
          },
        },
      },
    }
  },
  setOrderDeliveryTariffs({ payload, order_id }) {
    const orderDeliveryTariffs = this.order_delivery_tariffs
    payload.map(tarif => {
      const tariff = {}
      tariff[tarif.method] = {
        code: tarif.code,
        price: tarif.price,
        type: tarif.type,
      }
      orderDdeliveryTariffs[order_id] = {
        ...orderDeliveryTariffs[order_id],
        ...tariff,
      }
    })

    this.orderDeliveryTariffs = {
      ...this.orderDeliveryTariffs,
      ...orderDeliveryTariffs,
    }
  },
  setDeliveryTariffsAzerpoct({ payload, order_id }) {
    const customerStore = useCustomer()
    const orderDeliveryTariffs = this.orderDeliveryTariffs_azerpoct
    payload.map(tarif => {
      const tariff = {}
      tariff[tarif.method] = {
        code: tarif.code,
        price: tarif.price,
        type: tarif.type,
      }
      orderDeliveryTariffs[order_id] = {
        ...orderDeliveryTariffs[order_id],
        ...tariff,
      }
    })

    this.orderDeliveryTariffs_azerpoct = {
      ...this.orderDeliveryTariffs_azerpoct,
      ...orderDeliveryTariffs,
    }
  },
  async getOrderDeliveryTariffs({
    order_id,
    latitude,
    longitude,
    mode = 'ORDER',
    type_azerpoct = false,
  }) {
    const customerStore = useCustomer()
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL = (
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
      ).replace('/v1', '/v2')
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')
    }
    let options = {}
    if (mode === 'CHECKOUT') {
      options = {
        mode: 'CHECKOUT',
      }
    } else {
      options = {
        latitude,
        longitude,
      }
    }

    let urlDeliveryTariffs = `/orders/${order_id}/delivery-tariffs?${serialize(
      options,
    )}`
    if (!customerStore.isLoggedIn) {
      urlDeliveryTariffs = `/anonymous/orders/${order_id}/delivery-tariffs?${serialize(
        options,
      )}`
    }
    try {
      const { data } = await this.$nuxt.$axios.get(urlDeliveryTariffs)
      if (type_azerpoct) {
        this.setDeliveryTariffsAzerpoct({
          payload: data?.data,
          order_id,
        })
      } else {
        this.setOrderDeliveryTariffs({
          payload: data?.data,
          order_id,
        })
      }
      return data
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_ORDER_DELIVERY_TARIFFS',
        e,
        options,
      )
      throw e
    }
  },
  async getOrdersPackagesById(orderPackageId) {
    const customerStore = useCustomer()
    try {
      // this.$nuxt.$axios.defaults.baseURL =
      //   this.$nuxt.$config.CART_API_URL.replace('v1', 'v3')
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      const urlPackages = !customerStore.isLoggedIn
        ? `/anonymous/orders-packages/${orderPackageId}`
        : `/orders-packages/${orderPackageId}`
      return await this.$nuxt.$axios.get(urlPackages)
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'GET_ORDERS_PACKAGES_BY_ID',
        e,
        {
          orderPackageId,
        },
      )
    }
  },
  async changePaymentMethodForOrder({ order_id, data }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.put(
        `/orders/${order_id}/payment-method`,
        data,
      )
    } catch (e) {
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'CHANGE_PAYMENT_METHOD_FOR_ORDER',
        e,
        {
          order_id,
          data,
        },
      )
    }
  },
  async getCompareCount() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: {
          data: { count },
        },
      } = await this.$nuxt.$axios.get(`/comparisons/items/count`)
      this.compareCount = count
    } catch (e) {
      console.log('Error getCompareCount', e)
    }
  },
  setCompareUuids(payload) {
    const compare_uuids = []
    if (payload) {
      payload?.map(compareCategory => {
        if (compareCategory?.items) {
          compareCategory.items.forEach(compareItem => {
            compare_uuids.push(compareItem.product_id)
          })
        }
      })
      this.compareUuids = compare_uuids
      this.compare = payload
    } else {
      this.compareUuids = []
      this.compare = []
    }
  },
  setCompare({ payload, dataProducts }) {
    if (payload) {
      payload?.map(compareCategory => {
        if (compareCategory?.items) {
          if (dataProducts) {
            dataProducts.forEach(elP => {
              const indxIn = compareCategory.items.findIndex(
                item => item.product_id === elP.id,
              )
              if (compareCategory.items[indxIn]) {
                compareCategory.items[indxIn].image_url = elP.img_url_thumbnail
                compareCategory.items[indxIn].old_price = elP.old_price
                compareCategory.items[indxIn].price = elP.retail_price
                if (elP.default_marketing_name) {
                }
                compareCategory.items[indxIn].default_marketing_name =
                  elP.default_marketing_name
                compareCategory.items[indxIn].avail_check = elP.avail_check
                compareCategory.items[indxIn].default_offer_allow_qty =
                  elP.default_offer_allow_qty
                compareCategory.items[indxIn].offers = elP.offers
                compareCategory.items[indxIn].default_offer_id =
                  elP.default_offer_id
              }
            })
          }
        }
      })

      this.compare = payload
    } else {
      this.compare = []
    }
  },
  async getCompare(opaque_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.get(`/comparisons`)

      this.setCompareUuids(data)

      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const idS = this.compareUuids
      if (idS.length) {
        const options = {}
        options['q[id_in]'] = idS.join()
        options['q[response_mode]'] = 'products'
        options.per_page = idS.length
        options['q[opaque_id]'] = opaque_id
        options.include_fields = [
          'img_url_thumbnail',
          'id',
          'old_price',
          'retail_price',
          'default_marketing_name',
          'avail_check',
          'default_offer_allow_qty',
          'offers',
          'labels',
          'default_merchant_rating',
          'default_offer_id',
          'offers.id',
          'offers.installment_enabled',
          'offers.max_installment_months',
          'is_bulk_heavy',
          'offers.supplier_id',
        ].join()
        const {
          data: { products: dataProducts = [] },
        } = await this.$nuxt.$axios.get(
          `/v3/market/products?${serialize(options)}`,
        )

        this.setCompare({ payload: data, dataProducts })
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
            this.$nuxt.$config.WISH_LIST_API_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.WISH_LIST_API_URL
        }
        if (!this.compareCount) {
          const {
            data: {
              data: { count },
            },
          } = await this.$nuxt.$axios.get(`/comparisons/items/count`)
          this.compareCount = count
        }
      }
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'GET_COMPARE', e)
    }
  },
  async addToCompare({ product, category_id }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const options = { ...product }
      const {
        data: { data },
      } = await this.$nuxt.$axios.post(
        `/categories/${category_id}/comparison`,
        options,
      )
    } catch (e) {
      console.log('Error addToCompare', e)
      LOG_ERROR(this?.$rollbar, this?.$config, 'ADD_TO_COMPARE', e, {})
    }
  },
  async removeFromCompare({ product_id, category_id }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.delete(
        `/categories/${category_id}/comparison/items/${product_id}`,
      )
    } catch (e) {
      console.log('Error removeFromCompare', e)
    }
  },
  async removeFromCompareCategory(category_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.delete(
        `/categories/${category_id}/comparison`,
      )

      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: {
          data: { count },
        },
      } = await this.$nuxt.$axios.get(`/comparisons/items/count`)
      this.compareCount = count
    } catch (e) {
      console.log('Error removeFromCompareCategory', e)
    }
  },
  async goToCompare({ category_id, opaque_id }) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_WISH_LIST_API_URL ||
          this.$nuxt.$config.WISH_LIST_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.WISH_LIST_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.get(
        `/categories/${category_id}/comparison?q[opaque_id]=${opaque_id}`,
      )

      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const options = {
        'q[opaque_id]': opaque_id,
      }
      const idS = []

      if (data && data.items && data.items.length) {
        data.items.forEach(e => {
          idS.push(e.product_id)
        })
      }
      options['q[id_in]'] = idS.join()
      options.per_page = idS.length
      options.include_fields = [
        'img_url_thumbnail',
        'id',
        'old_price',
        'retail_price',
        'default_marketing_name',
        'status',
        'manufacturer',
        'avail_check',
        'default_offer_allow_qty',
        'product_labels',
        'offers',
        'default_merchant_rating',
        'default_offer_id',
        'categories',
        'categories.path_ids',
      ].join()
      const {
        data: { products = [] },
      } = await this.$nuxt.$axios.get(
        `/v3/market/products?${serialize(options)}`,
      )
      products.forEach(el => {
        const indxIn = data.items.findIndex(item => item.product_id === el.id)
        data.items[indxIn].image_url = el.img_url_thumbnail
        data.items[indxIn].old_price = el.old_price
        data.items[indxIn].price = el.retail_price
        data.items[indxIn].default_marketing_name = el.default_marketing_name
        data.items[indxIn].status = el.status
        data.items[indxIn].manufacturer = el.manufacturer
        data.items[indxIn].avail_check = el.avail_check
        data.items[indxIn].default_offer_allow_qty = el.default_offer_allow_qty
        data.items[indxIn].offers = el.offers
        data.items[indxIn].labels = el.product_labels
        data.items[indxIn].default_merchant_rating = el.default_merchant_rating
        data.items[indxIn].default_offer_id = el.default_offer_id
        data.items[indxIn].categories = el.categories
      })
      return data
    } catch (e) {
      console.log('Error goToCompare', e)
    }
  },
  async getPartnerAssessmentQuestions() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
          this.$nuxt.$config.ASSESSMENT_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.ASSESSMENT_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/assessment/mp-partner`,
      )
      this.assessment = { ...data, is_anonymous: false }
      return data
    } catch (e) {
      console.log('Error getPartnerAssessmentQuestions', e)
    }
  },
  async getProductAssessmentQuestions() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_ASSESSMENT_API_URL ||
          this.$nuxt.$config.ASSESSMENT_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.ASSESSMENT_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/api/v1/assessment/mp-product`,
      )
      this.assessment = { ...data, is_anonymous: false }
      return data
    } catch (e) {
      console.log('Error getProductAssessmentQuestions', e)
    }
  },
  async getViewedProductsUpdated(options) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const url = customerStore.isLoggedIn
        ? `/api/v1/products/blocks/history`
        : `/api/v1/products/blocks/history/session/${options?.session_id}`
      const { data } = await this.$nuxt.$axios.get(url)
      const products = normalizeNewCatalogProductItems(data || [])
      return products || []
    } catch (e) {
      console.log('Error: getViewedProductsUpdated', e)
      return []
    }
  },
  async getViewedProducts({ customer_id, opaque_id = '' }) {
    const customerStore = useCustomer()
    try {
      let url = customerStore.isLoggedIn
        ? `/api/v1/events/${customer_id}/freq_watched_products`
        : `/api/v1/anonymous/events/${customer_id}/freq_watched_products`
      url = opaque_id ? `${url}/${serialize(opaque_id)}` : url
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CLICKSTREAM_API_URL ||
          this.$nuxt.$config.CLICKSTREAM_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CLICKSTREAM_API_URL
      }
      const { data: products_ids } = await this.$nuxt.$axios.get(`${url}`)
      this.viewedProducts = products_ids
      return products_ids
    } catch (e) {
      LOG_ERROR(
        this?.$nuxt.$rollbar,
        this?.$nuxt.$config,
        'getViewedProducts',
        e,
        {
          customer_id,
        },
      )
    }
  },
  async viewProduct({ view_data }) {
    const customerStore = useCustomer()
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CLICKSTREAM_API_URL ||
          this.$nuxt.$config.CLICKSTREAM_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CLICKSTREAM_API_URL
      }
      const url = customerStore.isLoggedIn
        ? '/api/v1/events'
        : '/api/v1/anonymous/events'
      const {
        data: { data },
      } = await this.$nuxt.$axios.post(url, view_data)
      // commit('SET_COMPARE_UUIDS', [data])
    } catch (e) {
      LOG_ERROR(this?.$nuxt.$rollbar, this?.$nuxt.$config, 'viewProduct', e, {
        view_data,
      })
    }
  },
  async getPartner(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      return await this.$nuxt.$axios.get(`/partners/${id}`)
    } catch (e) {
      LOG_ERROR(this?.$nuxt.$rollbar, this?.$nuxt.$config, 'getPartner', e, id)
    }
  },
  async getOrderOtp(order_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: {
          data: { otp },
        },
      } = await this.$nuxt.$axios.get(`/orders/${order_id}/otp`)
      this.orderOtp = otp
    } catch (e) {
      console.log('Error: getOrderOtp', e)
    }
  },
  async getOrderTrackingStates() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.get('/order-tracking-states')
      this.orderTrackingStates = data
    } catch (e) {
      console.log('Error: getOrderTrackingStates', e)
    }
  },
  async getOrderCancelReasons() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: { data },
      } = await this.$nuxt.$axios.get('/order-cancel-reasons', {
        params: {
          responsibility: 'CUSTOMER',
        },
      })
      this.orderCancelReasons = data
    } catch (e) {
      console.log('Error: getOrderCancelReasons', e)
    }
  },
  setBlockScrollFavoritesProducts(val) {
    this.blockScrollFavoritesProducts = val
  },
  async mergeAnonymousWithLoggedInFavorites() {
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.WISH_LIST_API_URL.replace('v1', 'v2')
    try {
      const customerId =
        this.$nuxt.$auth.$storage.getLocalStorage('customer_id')
      await this.$nuxt.$axios.post(
        `/favorites/authorized/copy-from/${customerId}`,
      )
      this.anonFavorites = []
      this.anonFavoriteList = []
      this.anonFavoritesCount = null
      this.$nuxt.$auth.$storage.removeLocalStorage('customer_id')
    } catch (e) {
      console.log('Error: mergeAnonymousWithLoggedInFavorites', e)
    }
  },
  async addToFavoritesLogin() {
    const customerStore = useCustomer()
    const checkAuthToAddFavorites = async resolve => {
      if (customerStore.isLoggedIn) {
        setTimeout(async () => {
          await this.mergeAnonymousWithLoggedInFavorites()
          resolve()
        }, 1500)
      } else {
        setTimeout(async () => {
          await checkAuthToAddFavorites(resolve)
        }, 100)
      }
    }
    return new Promise(resolve => {
      checkAuthToAddFavorites(resolve)
    })
  },
  async GetBreadcrumbsCategorys({ categoryId = null }) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
    }
    const { data } = await this.$nuxt.$axios.get(
      `/api/v1/categories/${categoryId}`,
    )
    return data
  },
  async getMinInstallmentAmount() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: {
          data: { value },
          status,
        },
      } = await this.$nuxt.$axios.get(
        '/default-settings/MIN_INSTALLMENT_AMOUNT',
      )
      if (status === 'success') {
        this.minInstallmentAmount = value.number
      } else {
        this.minInstallmentAmount = 5
      }
    } catch (e) {
      this.minInstallmentAmount = 5
      console.log('Error: getMinInstallmentAmount', e)
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'getMinInstallmentAmount',
        e,
      )
    }
  },
  async getTagInfo(tag_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CATALOG_API_URL ||
          this.$nuxt.$config.CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CATALOG_API_URL
      }
      const {
        data: { data = [] },
      } = await this.$nuxt.$axios.get(`/v3/market/tags/${tag_id}`)
      return data
    } catch (e) {
      console.log('Error: getTagInfo', e)
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'getTagInfo', e)
    }
  },
  async getCustomerOrdersCount() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CART_API_URL ||
          this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const {
        data: {
          data: { count },
        },
      } = await this.$nuxt.$axios.get(
        '/orders-packages/successful-checkouts-count',
      )
      this.customerOrdersCount = count
    } catch (e) {
      console.log('Error: getCustomerOrdersCount', e)
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'getCustomerOrdersCount',
        e,
      )
    }
  },
  async getBrandPopularProducts(options) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const params = new URLSearchParams()
      Object.entries(options).forEach(([key, value]) => {
        params.append(key, String(value))
      })
      const {
        data: { products = [] },
      } = await this.$nuxt.$axios.get(`/api/v1/products?${params.toString()}`)
      this.PopularNowProducts = normalizeNewCatalogProductItems(
        products,
      ).filter(product => product.status === 'active')
    } catch (e) {
      console.log('getBrandPopularProducts', e)
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'getBrandPopularProducts',
        e,
      )
    }
  },

  async getPopularNow(id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const response = await this.$nuxt.$axios
        .get(`/api/v1/categories/${id}/blocks/popular`)
        .catch(e => {
          console.log(e)
        })
      const listProducts = response?.data?.products?.filter(
        product => product.status === 'active',
      )

      this.PopularNowProducts = normalizeNewCatalogProductItems(
        listProducts || [],
      )
    } catch (e) {
      console.log('Error getPopularNow', e)
      LOG_ERROR(this.$nuxt?.$rollbar, this.$nuxt?.$config, 'getPopularNow', e)
    }
  },
  async mainPageBestsellers() {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_NEW_CATALOG_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NEW_CATALOG_API_URL
      }
      const response = await this.$nuxt.$axios
        .get(`/api/v1/products/blocks/bestsellers`)
        .catch(e => {
          console.log(e)
        })

      this.products = normalizeNewCatalogProductItems(
        response?.data?.products || [],
      )
      return response?.data || []
    } catch (e) {
      console.log('Error mainPageBestsellers', e)
      LOG_ERROR(
        this.$nuxt?.$rollbar,
        this.$nuxt?.$config,
        'mainPageBestsellers',
        e,
      )
    }
  },
  async getCartProductMayInterest() {
    const grosseryStore = useGrosseryStore()
    const sellersId = this.cart?.sellers?.[0]?.id
    let listSellers = []
    // если первый продавец гросери берём только товары с гросери
    if (grosseryStore.isGrossery(sellersId)) {
      listSellers = [this.cart?.sellers?.[0]]
    } else {
      // если не гросери исключаем гросери
      listSellers = this.cart?.sellers.filter(
        seller => !grosseryStore.isGrossery(seller.id),
      )
    }
    const productIds =
      listSellers?.flatMap(seller =>
        seller.line_items.map(item => item.product_id),
      ) || []

    if (productIds?.length) {
      const options = {
        'product_ids[]': productIds,
      }
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NEW_CATALOG_API_URL
      const set_url = new URLSearchParams(options).toString()
      const response = await this.$nuxt.$axios
        .get(`/api/v1/products/blocks/cross-sales?${set_url}`)
        .catch(e => {
          console.log('Error getCartProductMayInterest', e)
        })
      const listNotCartProducts =
        response?.data?.products?.filter(
          product => !productIds.includes(product.id),
        ) || []
      const listShow = Array.from(
        new Map(listNotCartProducts.map(item => [item.id, item])).values(),
      )
      this.listProductMayInterest = normalizeNewCatalogProductItems(
        (listShow || []).filter(
          product => !productIds.includes(product?.product_id),
        ),
      )
    }
  },
}
