import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration, reportingObserverIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = []

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002Fslog.umico.az",
    environment:"production",
    denyUrls:[new RegExp(".*\\.?clarity.ms.*", ""),new RegExp(".*\\.?cloudflareinsights.com.*", ""),new RegExp(".*\\.?doubleclick.net.*", ""),new RegExp(".*\\.?creativecdn.com.*", ""),new RegExp(".*\\.?googletagmanager.com.*", ""),new RegExp(".*\\.?chat2desk.com.*", ""),new RegExp(".*\\.?adform.net.*", ""),new RegExp(".*\\.?adnxs.com.*", ""),new RegExp(".*\\.?exponea.com.*", ""),new RegExp(".*\\.?teads.tv.*", ""),new RegExp(".*\\.?24ttl.stream.*", ""),new RegExp(".*\\.?smartadserver.com.*", "")],
    tracesSampleRate:0.1,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
    reportingObserverIntegration({ types:["crash"] }),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":true}, {"tracesSampleRate":0.1})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
