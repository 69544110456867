import { useMarketStore } from '@/stores/market'
import { discountCalculate } from '@/utilities'
import { useEventDataLayer } from '@/stores/event_data_layer'
import { useCheckoutStore } from '@/stores/checkout'

const filterBloomReachData = bloomreachData =>
  Object.keys(bloomreachData).reduce((acc, key) => {
    const value = bloomreachData[key]

    if (
      value !== undefined &&
      value !== null &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      acc[key] = value
    }

    return acc
  }, {})
export default {
  viewItem(listName) {
    const marketStore = useMarketStore()
    const categoryDetails = marketStore.breadcrumbs
    const bloomReachData = {
      product_id:
        marketStore.product?.id?.toString() ||
        marketStore.product?.product_id?.toString(),
      title: marketStore.product?.name || marketStore.product?.product_name,
      brand: marketStore.product?.brand?.name || '',
      categories_path:
        categoryDetails.map(category => category.name).join(' > ') || '',
      category_id: marketStore.product?.category_id?.toString() || '',
      category_ids: categoryDetails.map(category => category.id) || '',
      price: marketStore.product?.default_offer?.retail_price || '',
      item_list_name: listName,
      discount_percentage:
        parseFloat(
          discountCalculate(
            marketStore?.product?.default_offer?.old_price,
            marketStore?.product?.default_offer?.retail_price,
          ),
        ) || 0,
      discount_value:
        marketStore?.product?.default_offer?.old_price !== 0
          ? parseFloat(
              (
                marketStore?.product?.default_offer?.old_price -
                marketStore?.product?.default_offer?.retail_price
              ).toFixed(2),
            )
          : 0,
      original_price:
        marketStore?.product?.default_offer?.old_price ||
        marketStore?.product?.default_offer?.retail_price ||
        '',
      language: this.$nuxt.i18n.locale || '',
    }
    if (categoryDetails) {
      categoryDetails.forEach((category, index) => {
        bloomReachData[`category_${index + 1}`] = category.name || ''
      })
    }
    if (typeof exponea !== 'undefined') {
      exponea.track('view_item', filterBloomReachData(bloomReachData))
    }
  },
  viewCategory(options) {
    const route = this.$nuxt.route.name

    if (typeof exponea !== 'undefined') {
      const marketStore = useMarketStore()
      let type = ''
      if (route.includes('categories')) type = 'category'
      if (route.includes('search')) type = 'search'
      if (route.includes('brand')) type = 'brand'
      if (route.includes('top-products')) type = 'top_products'
      if (route.includes('mega-discounts')) {
        type = 'max_discount'
      }
      if (route.includes('merchant')) type = 'seller'
      if (route.includes('sale-day')) type = 'flash_sale'
      if (route.includes('tags')) type = 'tags'
      if (route.includes('new-products')) type = 'new_products'
      if (route.includes('promo_page') || route.includes('promotion')) {
        type = 'promotion'
      }

      const items = []
      const products_list = options.products || marketStore.products
      if (products_list.length && type !== 'promotion') {
        products_list.slice(0, 10).map((product, index) => {
          items.push(product?.id?.toString())
        })
      }
      const categoryIds =
        type !== 'promotion'
          ? marketStore.breadcrumbs?.map(category => category.id?.toString())
          : []
      let categoryName
      let categoryId
      if (type === 'category') {
        categoryName = marketStore.selfCategory?.data?.name
        categoryId = marketStore.selfCategory?.data?.id?.toString()
      }

      const categoriesPath =
        type !== 'promotion'
          ? marketStore?.breadcrumbs?.map(category => category.name).join(' > ')
          : ''

      const bloomreachData = {
        category_id:
          options?.selectedCategory?.category_id?.toString() ||
          options?.selectedCategory?.id?.toString() ||
          categoryId ||
          '',
        category_name:
          options?.selectedCategory?.category_name ||
          options?.selectedCategory?.name ||
          options?.selectedCategory ||
          categoryName ||
          options?.title?.text ||
          marketStore.tag?.name ||
          '',
        category_listed_products: items,
        categories_path: categoriesPath || '',
        categories_ids: options?.ids || categoryIds || '',
        language: this.$nuxt.i18n.locale || '',
        item_list_name: type,
        partner: options?.partner || '',
      }

      if (type === 'category') {
        marketStore.breadcrumbs?.forEach((crumb, index) => {
          bloomreachData[`category_${index + 1}`] = crumb?.name || ''
        })
      } else {
        options?.categoryNames?.forEach((category, index) => {
          bloomreachData[`category_${index + 1}`] = category || ''
        })
      }

      exponea.track('view_category', filterBloomReachData(bloomreachData))
    }
  },
  cartUpdate(action, source, button, offer = null) {
    const marketStore = useMarketStore()
    const cartData = marketStore?.cart
    const productData = this.addedProduct

    const productList = () =>
      cartData?.sellers.flatMap(seller =>
        seller.line_items.map(item => ({
          product_id: item.product_id?.toString(),
          quantity: item.quantity,
        })),
      )
    const cartUpdateData = {
      action: action || '',
      product_id:
        productData?.product_id?.toString() ||
        productData?.id?.toString() ||
        null,
      title: productData?.name || productData?.product_name || '',
      brand: productData?.manufacturer || productData?.brand || '',
      category_id:
        productData?.category_id?.toString() ||
        productData?.category?.id?.toString() ||
        '',
      price:
        offer?.retail_price ||
        productData?.retail_price ||
        productData?.price ||
        '',
      discount_percentage:
        parseFloat(
          discountCalculate(productData?.old_price, productData?.retail_price),
        ) || 0,
      discount_value: productData?.old_price
        ? parseFloat(
            (productData?.old_price - productData?.retail_price).toFixed(2),
          )
        : 0,
      original_price: productData?.old_price || productData?.retail_price || '',
      product_list: productList(),
      product_ids: productList()?.map(product => product?.product_id),
      total_quantity: marketStore?.cartCount,
      total_price: cartData?.total_amount,
      page_type: source,
      button_type: button,
      partner:
        productData?.seller_marketing_name?.name ||
        productData?.seller ||
        productData?.default_marketing_name?.name ||
        '',
      language: this.$nuxt.i18n.locale || '',
      local_currency: 'AZN',
    }
    if (typeof exponea !== 'undefined') {
      exponea.track('cart_update', filterBloomReachData(cartUpdateData))
    }
  },
  checkout(orderPackage) {
    const EventDataLayer = useEventDataLayer()
    const checkoutStore = useCheckoutStore()
    const extractOrderDetails = orderPackage => {
      const totalQty = orderPackage?.orders?.reduce((total, order) => {
        return (
          total +
          order.line_items?.reduce(
            (orderSum, lineItem) => orderSum + lineItem?.quantity,
            0,
          )
        )
      }, 0)
      const lang = this.$nuxt.i18n.locale

      const lineItemDetails = orderPackage?.orders?.flatMap(order =>
        order.line_items.map(lineItem => ({
          product_id: lineItem.product_id?.toString(),
          quantity: lineItem.quantity,
        })),
      )

      const productIds = orderPackage?.orders?.flatMap(order =>
        order?.line_items?.map(lineItem => lineItem?.product_id?.toString()),
      )

      return {
        total_quantity: totalQty || null,
        product_list: lineItemDetails || null,
        product_ids: productIds || null,
        order_package: orderPackage?.id || null,
        total_price: orderPackage?.total_amount || null,
        checkout_method: EventDataLayer.begin_checkout_method || null,
        payment_type: orderPackage?.payment_method || null,
        language: lang || null,
        local_currency: 'AZN',
      }
    }
    if (typeof exponea !== 'undefined') {
      exponea.track(
        'checkout',
        filterBloomReachData(extractOrderDetails(orderPackage)),
      )
    }
  },
  favoritesUpdate(productData, action, isLoggedIn) {
    const marketStore = useMarketStore()

    let product_ids = []
    if (isLoggedIn) {
      product_ids = marketStore.favoriteUUIDS.map(id => String(id))
    } else {
      product_ids = marketStore.anonFavoriteList.map(id => String(id))
    }

    const favoritesData = {
      ...productData,
      discount_percentage:
        parseFloat(
          discountCalculate(productData?.original_price, productData?.price),
        ) || 0,
      discount_value: productData?.original_price
        ? parseFloat(
            (productData?.original_price - productData?.price).toFixed(2),
          )
        : 0,
      action,
      product_ids,
      total_quantity: product_ids.length,
      button_type: action,
      language: this.$nuxt.i18n.locale || '',
      local_currency: 'AZN',
    }
    if (typeof exponea !== 'undefined') {
      exponea.track('favorites_update', filterBloomReachData(favoritesData))
    }
  },
}
