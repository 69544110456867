
import { mapGetters } from 'vuex'
import { mapState, mapWritableState, storeToRefs } from 'pinia'
import { useDcmStore } from '~/stores/dcm'
import { useMarketStore } from '~/stores/market'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'LangSelect',
  inject: {
    isErrorLayout: {
      default: false,
    },
  },
  setup() {
    const marketStore = useMarketStore()
    const uiStore = useUiStore()
    const { isLangMenuOpen: isOpen, resolution } = storeToRefs(uiStore)
    const { tag, selfCategory } = storeToRefs(marketStore)
    return {
      marketStore,
      uiStore,
      isOpen,
      resolution,
      tag,
      selfCategory,
    }
  },
  data() {
    return {
      options: [
        {
          label: 'Русский',
          value: 'RU',
          src: '/images/flag-ru.svg',
        },
        {
          label: 'Azərbaycan',
          value: 'AZ',
          src: '/images/flag-az.svg',
        },
      ],
    }
  },

  computed: {
    ...mapWritableState(useDcmStore, ['smart_category', 'promotions_content']),
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
    }),
    selected() {
      const lang = this.$i18n.locale
      return this.options.find(o => o.value?.toLowerCase() === lang)
    },
    src() {
      return (this.selected && this.selected.src) || ''
    },
    value() {
      return (this.selected && this.selected.value) || ''
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.$emit('open')
        document.addEventListener(
          'click',
          () => {
            this.isOpen = false
          },
          { once: true },
        )
      } else this.$emit('close')
    },
  },

  methods: {
    onClickButton() {
      if (!this.isOpen) {
        const isMobile =
          this.resolution === 'sm' ||
          this.resolution === 'md' ||
          this.resolution === 'lg'
        if (!isMobile) {
          this.uiStore.isHeaderUserOpen = false
        } else {
          this.uiStore.clearBurgerMenu()
        }
        setTimeout(() => {
          this.isOpen = true
        }, 30)
      }
    },

    onClickItem(lang) {
      this.$emit('click-item')
      this.isOpen = false
      this.changeLanguage(lang)
    },
    async changeLanguage(lang) {
      lang = lang?.toLowerCase()
      if (lang === this.$i18n?.locale) return
      window.location = await this.getNewPathByLang(lang)
    },

    getNewPathByLang(alternateLang) {
      const fullPath = this.$route.fullPath
      if (this.isErrorLayout) {
        const localePattern = /^\/[a-z]{2}\//
        const hasLocale = localePattern.test(fullPath)

        let pathWithoutLocale = fullPath
        if (hasLocale) {
          pathWithoutLocale = fullPath.replace(localePattern, '/')
        }
        const prefix = alternateLang === 'ru' ? '/ru' : ''

        return `${prefix}${pathWithoutLocale}`
      } else if (fullPath.includes('categories'))
        return this.getAlternateCategoryHref(alternateLang)
      else if (
        fullPath.includes('product') &&
        !fullPath.includes('new-products') &&
        !fullPath.includes('top-products')
      )
        return this.getAlternateProductHref(alternateLang)
      else if (
        fullPath.includes('tags') &&
        this.$route.name?.includes('tags-tag')
      )
        return this.getAlternateTagHref(alternateLang)
      else if (fullPath.includes('promotion'))
        return this.getAlternatePromotionHref(alternateLang)
      else return this.switchLocalePath(alternateLang)
    },

    getAlternateCategoryHref(alternateLang) {
      const slug =
        this.selfCategory?.data &&
        this.selfCategory?.data['slugged_name_' + alternateLang]
      const prefix = alternateLang === 'ru' ? '/ru' : ''
      if (slug) {
        return (
          `${window.location.origin}${prefix}/categories/${this.selfCategory?.data.id}-${slug}` ||
          ''
        )
      } else {
        return `${window.location.origin}${prefix}/categories` || ''
      }
    },

    getAlternateProductHref(alternateLang) {
      const prefix = alternateLang === 'ru' ? '/ru' : ''
      const slug =
        this.marketStore.product &&
        this.marketStore.product['slugged_name_' + alternateLang]
      return (
        (slug &&
          `${window.location.origin}${prefix}/product/${
            this.marketStore.product.id
          }-${slug}${this.generateQueryTemplate()}`) ||
        ''
      )
    },

    getAlternateTagHref(alternateLang) {
      const prefix = alternateLang === 'ru' ? '/ru' : ''
      const slug = this.tag && this.tag['slugged_name_' + alternateLang]
      return (
        slug &&
        `${
          window.location.origin
        }${prefix}/tags/${slug}${this.generateQueryTemplate()}`
      )
    },

    async getAlternatePromotionHref(alternateLang) {
      const dcmStore = useDcmStore()
      return await dcmStore.getSmartCategory().then(() => {
        const current_category = this.smart_category.find(
          item => item.id === this.promotions_content.title_id,
        )
        return this.setUrl(current_category.action_url, alternateLang)
      })
    },
    setUrl(action_url, alternateLang) {
      const prefix = alternateLang === 'ru' ? '/ru' : ''
      const index_for_slice = action_url.lastIndexOf('promotion')
      const slag = action_url.slice(index_for_slice + 10, action_url.length)
      return `${window.location.origin}${prefix}/promotion/${slag}`
    },
    generateQueryTemplate() {
      const routeQueries = { ...this.$route.query }
      const queries = new URLSearchParams()
      Object.entries(routeQueries).forEach(([key, value]) => {
        queries.append(key, `${value}`)
      })
      return Object.keys(routeQueries).length ? `?${queries.toString()}` : ''
    },
  },
}
