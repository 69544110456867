import { render, staticRenderFns } from "./FooterMenu.vue?vue&type=template&id=2ec86666"
import script from "./FooterMenu.vue?vue&type=script&lang=js"
export * from "./FooterMenu.vue?vue&type=script&lang=js"
import style0 from "./FooterMenu.vue?vue&type=style&index=0&id=2ec86666&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterSlideUpDownBlock: require('/usr/src/app/components/Footer/SlideUpDownBlock.vue').default,FooterMenuList: require('/usr/src/app/components/Footer/MenuList.vue').default})
